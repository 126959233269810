import React from "react";
import { Button } from "@mui/material";
// import { DocumentsFormLogic } from "../../../create-loan/documents/DocumentsForm";
// import { useDispatch } from "react-redux";
// import { isOrigUser } from "../../../utils/AccessManagement";
import { useDrawsStyles } from "../Draws.styles";

// const DocumentsFormLogic = lazy(() =>
//   import("../../../create-loan/documents/DocumentsForm").then(module => ({
//     default: module.DocumentsFormLogic
//   }))
// );

export const DrawDocsButtonsWrapper = (props: {
  drawId: any;
  loanId: any;
  disable: boolean;
  inspectionDisable?: boolean;
}) => {
  //   const isOrig = isOrigUser();
  // const dispatch = useDispatch();
  const classes = useDrawsStyles();
  const { drawId, loanId, disable, inspectionDisable } = props;

  //   const [purchasedDocsToPass, setPurchasedDocsToPass] = useState<any[]>([]);
  //   const [isDocsLoading, setIsDocsLoading] = useState<boolean>(true);

  const tabRedirect = (params: string) => {
    //navigate(`/drawDetails/${loanId}/${drawId}/${params}`);
    window.open(`/drawDetails/${loanId}/${drawId}/${params}`, "_blank");
  };

  //   const docsListToPass = {
  //     docsLoaded: true,

  //     files: purchasedDocsToPass,
  //     failedUploads: 0,
  //     successfulUploads: purchasedDocsToPass.length
  //   };
  return (
    <>
      <Button
        variant="contained"
        className={classes.docsImportBtn}
        style={{ marginRight: "10px" }}
        disabled={disable}
        onClick={() => tabRedirect("documentTagging")}
      >
        Documents Tagging
      </Button>
      <Button
        variant="contained"
        className={classes.docsImportBtn}
        style={{ marginRight: "10px" }}
        disabled={inspectionDisable}
        onClick={() => tabRedirect("budgetReconciliation")}
      >
        Budget Reconciliation
      </Button>
    </>
  );
};
