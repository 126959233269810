import { LoanStatusEnum } from "@toorak/tc-common-util-sdk";
import {
  ToorakProductEnum,
  cashOutNot,
  exitStrategyDropDown,
  gUpFESExitStrategyOptions,
  gUpFESLoanStructure,
  gUpLoanTerm,
  irFundingSources,
  multiFamilyRecourse,
  yesNoDropdown
} from "../../../create-loan/constants/loanCreationDropDownValues";
import { thirtyYearLoanFields } from "../../../create-loan/constants/loanFieldType";
import {
  CondoEligibilityDropDown,
  CreditEventDropDown,
  CrossDropDown,
  CutOffDateTextField,
  FirstPaymentDateTextField,
  LoanPurposeDropDown,
  MaturityDateTextField,
  NoOfPropertiesTextField,
  OriginationDateTextField,
  PrimaryLoanID30YearLoanTextField,
  RecourseDropDown,
  ToorakProductDropDown
} from "../../../create-loan/form-components/LoanInformation";
import {
  condoEligibilityValues,
  loanPurposeDropDownValues,
  loanStructureDropDownValue,
  loanStructureValues,
  recourseDropDownValues,
  thirtyYearCreditEvent,
  thirtyYearSelectedCross,
  thirtyYrLoanPurposes,
  toorakProductDropDown,
  toorakProductValues,
  yOrNDropDown
} from "../../constants";
import { isLATUser, isOrigUser } from "../../../utils/AccessManagement";
import { LoanStage } from "@toorak/tc-common-fe-sdk";
import { LoanSizerEnum } from "../../../frontend-sizer/FrontendSizerTemplate";
import { getBooleanDropDownValue } from "../../views/common/util";
import { get } from "http";

const sellerName = {
  fieldName: "sellerPartyId",
  editedLabelPath: "sellerPartyId",
  fieldLabel: "Seller Name",
  required: () => true,
  fieldType: (loanType: string) => "dropDown",
  dropDownData: [],
  colSpan: 4,
  isVisible: () => true,
  fieldComponent: null,
  errorMsg: "Please select value",
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const primaryLoanId = {
  fieldName: "primaryLoanID",
  backendKey: "primaryLoanId",
  editedLabelPath: "primaryLoanId",
  fieldLabel: "Originator Loan Id",
  required: () => true,
  fieldType: (loanType: string) => "string",
  colSpan: 4,
  isVisible: () => true,
  fieldComponent: PrimaryLoanID30YearLoanTextField,
  errorMsg: "Please add primary loan id",
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const bridgePrimaryLoanId = {
  fieldName: "primaryLoanID",
  backendKey: "primaryLoanId",
  editedLabelPath: "primaryLoanId",
  fieldLabel: "Primary Loan Id",
  required: () => true,
  fieldType: (loanType: string) => "string",
  colSpan: 4,
  isVisible: () => true,
  fieldComponent: PrimaryLoanID30YearLoanTextField,
  errorMsg: "Please add primary loan id",
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const cutOffDate = {
  fieldName: "cutOffDate",
  backendKey: "cutOffDate",
  editedLabelPath: "cutOffDate",
  fieldLabel: "Cut-off Date",
  required: () => true,
  fieldType: (loanType: string) => "date",
  colSpan: 4,
  isVisible: () => true,
  fieldComponent: CutOffDateTextField,
  errorMsg: "Please select date",
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const firstPaymentDate = {
  fieldName: "firstPaymentDate",
  backendKey: "firstPaymentDateOfLoan",
  editedLabelPath: "firstPaymentDate",
  fieldLabel: "First Payment Date of Loan",
  required: () => true,
  fieldType: (loanType: string) => "date",
  colSpan: 4,
  isVisible: () => true,
  fieldComponent: FirstPaymentDateTextField,
  errorMsg: "Please select date",
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const dscrLoanPurpose = {
  fieldName: "loanPurpose",
  backendKey: "loanPurpose",
  editedLabelPath: "loanPurpose",
  fieldLabel: "Loan Purpose",
  required: () => true,
  fieldType: (loanType: string) => "dropDown",
  dropDownData: thirtyYrLoanPurposes || [],
  colSpan: 4,
  isVisible: (loanInformation: any, loanType: any, isSubmitted: boolean) =>
    loanType === "InvestorDSCR",
  fieldComponent: LoanPurposeDropDown,
  errorMsg: "Please select value",
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const bridgeLoanPurpose = {
  fieldName: "loanPurpose",
  backendKey: "loanPurpose",
  editedLabelPath: "loanPurpose",
  fieldLabel: "Loan Purpose",
  required: () => true,
  fieldType: (loanType: string) => "dropDown",
  dropDownData: loanPurposeDropDownValues || [],
  colSpan: 4,
  isVisible: (loanInformation: any, loanType: any, isSubmitted: boolean) =>
    loanType === "BridgeLoan",
  fieldComponent: LoanPurposeDropDown,
  errorMsg: "Please select value",
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const originalMaturityDate = {
  fieldName: "maturityDate",
  backendKey: "originalMaturityDate",
  editedLabelPath: "maturityDate",
  fieldLabel: "Original Maturity Date",
  required: () => true,
  fieldType: (loanType: string) => "date",
  colSpan: 4,
  isVisible: () => true,
  fieldComponent: MaturityDateTextField,
  errorMsg: "Please select date",
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const maturityDate = {
  fieldName: "maturityDate",
  backendKey: "originalMaturityDate",
  editedLabelPath: "maturityDate",
  fieldLabel: "Maturity Date",
  required: () => true,
  fieldType: (loanType: string) => "date",
  colSpan: 4,
  isVisible: () => true,
  fieldComponent: MaturityDateTextField,
  errorMsg: "Please select date",
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const originationDate = {
  fieldName: "originationDate",
  backendKey: "originationDate",
  editedLabelPath: "originationDate",
  fieldLabel: "Origination Date",
  required: () => true,
  fieldType: (loanType: string) => "date",
  colSpan: 4,
  isVisible: () => true,
  fieldComponent: OriginationDateTextField,
  errorMsg: "Please select date",
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const selectedCross = {
  fieldName: "selectedCross",
  backendKey: "crossCollaterlization",
  editedLabelPath: "selectedCross",
  fieldLabel: "Cross Collaterlization (Y/N)",
  required: () => false,
  fieldType: () => "booleanDropDown",
  getValue: (loanInformation: any) => {
    return getBooleanDropDownValue(loanInformation.selectedCross, true);
  },
  dropDownData: thirtyYearSelectedCross || [],
  colSpan: 4,
  isVisible: (loanInformation: any) =>
    !(loanInformation?.numberOfProperties <= 1),
  fieldComponent: CrossDropDown,
  errorMsg: "Please select value",
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const bridgeSelectedCross = {
  fieldName: "selectedCross",
  backendKey: "crossCollaterlization",
  editedLabelPath: "selectedCross",
  fieldLabel: "Cross Collaterlization (Y/N)",
  required: () => false,
  fieldType: () => "booleanDropDown",
  getValue: (loanInformation: any) => {
    return getBooleanDropDownValue(loanInformation.selectedCross, false);
  },
  dropDownData:
    yOrNDropDown.map((item) => ({
      value: item[0].toUpperCase(),
      label: item
    })) || [],
  colSpan: 4,
  isVisible: () => true,
  fieldComponent: CrossDropDown,
  errorMsg: "Please select value",
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const selectedRecourse = {
  fieldName: "selectedRecourse",
  backendKey: "recourseString",
  editedLabelPath: "selectedRecourse",
  fieldLabel: "Recourse",
  required: () => false,
  fieldType: (loanType: string) => "dropDown",
  dropDownData: recourseDropDownValues || [],
  colSpan: 4,
  isVisible: (loanInformation: any) => true,
  fieldComponent: RecourseDropDown,
  errorMsg: "Please select value",
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const selectedToorakProduct = {
  fieldName: "selectedToorakProduct",
  backendKey: "toorakProduct",
  editedLabelPath: "selectedToorakProduct",
  fieldLabel: "Toorak Product",
  required: (loanStage: any) =>
    thirtyYearLoanFields.selectedToorakProduct?.optionalIn?.includes(loanStage),
  fieldType: (loanType: string) => "dropDown",
  dropDownData: toorakProductValues || [],
  colSpan: 4,
  isVisible: (loanInformation: any) => true,
  fieldComponent: ToorakProductDropDown,
  errorMsg: "Please Add Arm Product type",
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable,
  disabled: (isDataEntry: any) => !isDataEntry
};

const selectedBridgeToorakProduct = {
  fieldName: "selectedToorakProduct",
  backendKey: "toorakProduct",
  editedLabelPath: "selectedToorakProduct",
  fieldLabel: "Toorak Product",
  required: (loanStage: any) => true,
  fieldType: (loanType: string) => "dropDown",
  dropDownData:
    toorakProductDropDown.map((item) => ({
      disabled: item === ToorakProductEnum.Rental,
      value: item,
      label: item
    })) || [],
  colSpan: 4,
  isVisible: (loanInformation: any) => true,
  disabled: (isDataEntry: any) => !isDataEntry,
  fieldComponent: ToorakProductDropDown,
  errorMsg: "Please Add Arm Product type",
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const numberOfProperties = {
  fieldName: "numberOfProperties",
  backendKey: "noOfProperties",
  editedLabelPath: "numberOfProperties",
  fieldLabel: "Number of Properties",
  required: (loanStage: any) =>
    !thirtyYearLoanFields.numberOfProperties?.optionalIn?.includes(loanStage),
  fieldType: (loanType: string) => "number",
  colSpan: 4,
  isVisible: (loanInformation: any) => true,
  fieldComponent: NoOfPropertiesTextField,
  errorMsg: "Please Add number of properties",
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const condoEligibility = {
  fieldName: "condoEligibility",
  backendKey: "condoEligibility",
  editedLabelPath: "condoEligibility",
  fieldLabel: "Condo Eligibility",
  required: (loanStage: any) =>
    thirtyYearLoanFields.condoEligibility?.optionalIn?.includes(loanStage),
  fieldType: (loanType: string) => "dropDown",
  dropDownData: condoEligibilityValues || [],
  colSpan: 4,
  isVisible: (loanInformation: any) => true,
  fieldComponent: CondoEligibilityDropDown,
  errorMsg: "Please Add Arm Product type",
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const creditEvent = {
  fieldName: "creditEvent",
  backendKey: "creditEvent",
  editedLabelPath: "creditEvent",
  fieldLabel: "FC/SS/DIL/BK7 36 - 47mo",
  required: (loanStage: any) => false,
  fieldType: (loanType: string) => "dropDown",
  dropDownData: thirtyYearCreditEvent || [],
  colSpan: 4,
  isVisible: (loanInformation: any) => true,
  fieldComponent: CreditEventDropDown,
  errorMsg: "Please select value",
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const expectedClosingDate = {
  fieldName: "expectedClosingDate",
  editedLabelPath: "expectedClosingDate",
  fieldLabel: "Expected Date of Closing",
  required: (loanStage: any) => true,
  fieldType: () => "date",
  colSpan: 4,
  isVisible: (loanInformation: any) => true,
  errorMsg: "Please select date",
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const cashOutFlag = {
  fieldName: "cashOutFlag",
  editedLabelPath: "cashOutFlag",
  fieldLabel: "Cash Out Flag",
  required: (loanStage: any) => false,
  fieldType: () => "dropDown",
  colSpan: 4,
  dropDownData: cashOutNot.map((item: any) => ({
    value: item,
    label: item
  })),
  isVisible: (loanInformation: any) => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const borrowerProceeds = {
  fieldName: "borrowerProceeds",
  editedLabelPath: "borrowerProceeds",
  fieldLabel: "Cash Out Refinance",
  required: (loanStage: any) => false,
  fieldType: () => "dropDown",
  colSpan: 4,
  dropDownData: yesNoDropdown.map((item: any) => ({
    value: item,
    label: item
  })),
  isVisible: (loanInformation: any) => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const loanTerm = {
  fieldName: "loanTerm",
  editedLabelPath: "loanTerm",
  fieldLabel: "Loan Term (Months)",
  required: (loanStage: any) => true,
  fieldType: () => "number",
  colSpan: 4,
  isVisible: (loanInformation: any) => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const fundingChannel = {
  fieldName: "selectedFundingChannel",
  editedLabelPath: "fundingType",
  fieldLabel: "Funding Channel",
  required: (loanStage: any) => true,
  dropDownData: [],
  getDropDownData: (loanStage: any, fundingChannelValues: any) =>
    loanStage === LoanStage.post
      ? fundingChannelValues
        .map((item: any) => ({
          value: item,
          label: item
        }))
        .filter((channelItem: any) =>
          ["Normal", "Warehouse Funding"].includes(channelItem.value)
        )
      : fundingChannelValues.map((item: any) => ({
        value: item,
        label: item
      })),
  fieldType: () => "dropDown",
  colSpan: 4,
  isVisible: (loanInformation: any, loanType: any, isSubmitted: boolean) =>
    true,
  isViewMode: (
    isEvaluationPanelVisible: any,
    isEditable: any,
    loanState: any,
    loanStage: any
  ) =>
    [LoanStatusEnum.PurchaseInitiated, LoanStatusEnum.Purchased].includes(
      loanState
    ) ||
    [LoanStage.post].includes(loanStage) ||
    isOrigUser() ||
    (isEvaluationPanelVisible && !isEditable)
};

const bridgeFundingChannel = {
  fieldName: "selectedFundingChannel",
  editedLabelPath: "fundingType",
  fieldLabel: "Funding Channel",
  required: (loanStage: any) => true,
  dropDownData: [],
  getDropDownData: (loanStage: any, fundingChannelValues: any) =>
    loanStage === LoanStage.post
      ? fundingChannelValues.map((item: any) => ({
        value: item,
        label: item,
        disabled: item !== "Warehouse Funding" && item !== "Normal"
      }))
      : // .filter((channelItem: any) => //TODO: need to check whether to filter or noe
      //   ["Normal", "Warehouse Funding"].includes(channelItem.value)
      // )
      fundingChannelValues.map((item: any) => ({
        value: item,
        label: item
      })),
  fieldType: () => "dropDown",
  colSpan: 4,
  isVisible: (loanInformation: any, loanType: any, isSubmitted: boolean) =>
    isSubmitted,
  isViewMode: (
    isEvaluationPanelVisible: any,
    isEditable: any,
    loanState: any,
    loanStage: any
  ) =>
    [LoanStatusEnum.PurchaseInitiated, LoanStatusEnum.Purchased].includes(
      loanState
    ) ||
    // [LoanStage.post].includes(loanStage) ||
    isOrigUser() ||
    (isEvaluationPanelVisible && !isEditable)
};

const accrualDate = {
  fieldName: "accrualDate",
  backendKey: "accrualDate",
  editedLabelPath: "accrualDate",
  fieldLabel: "Interest Accrual Date",
  required: (loanStage: any) => false,
  fieldType: () => "date",
  colSpan: 4,
  isVisible: (loanInformation: any, loanType: any, isSubmitted: boolean) =>
    isSubmitted,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) => isLATUser() ? isEvaluationPanelVisible && !isEditable : true
};

const nextPaymentDueDate = {
  fieldName: "nextPaymentDueDate",
  editedLabelPath: "nextPaymentDueDate",
  fieldLabel: "Next Payment Due Date",
  required: (loanStage: any) => false,
  fieldType: () => "date",
  colSpan: 4,
  isVisible: (loanInformation: any, loanType: any, isSubmitted: boolean) =>
    isSubmitted,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isLATUser() ? isEvaluationPanelVisible && !isEditable : true
};

const rentalLoanStructure = {
  fieldName: "selectedLoanStructure",
  backendKey: "loanStructure",
  editedLabelPath: "selectedLoanStructure",
  editedLabel: "loanStructure",
  fieldLabel: "Loan Structure",
  dropDownData: loanStructureDropDownValue || [],
  required: () => true,
  fieldType: () => "dropDown",
  isVisible: (loanInformation: any, loanType: any) =>
    loanInformation.selectedToorakProduct === ToorakProductEnum.Rental &&
    loanType === "BridgeLoan",
  colSpan: 4,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const bridgeLoanStructure = {
  fieldName: "selectedLoanStructure",
  backendKey: "loanStructure",
  editedLabelPath: "selectedLoanStructure",
  fieldLabel: "Loan Structure",
  dropDownData: loanStructureValues || [],
  required: () => true,
  fieldType: () => "dropDown",
  isVisible: (loanInformation: any, loanType: any) =>
    loanInformation.selectedToorakProduct !== ToorakProductEnum.Rental &&
    loanType === "BridgeLoan",
  colSpan: 4,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const bridgeGrUpLoanStructure = {
  fieldName: "loanStructure",
  backendKey: "loanStructure",
  editedLabelPath: "selectedLoanStructure",
  fieldLabel: "Loan Structure",
  dropDownData:
    gUpFESLoanStructure.map((item) => ({
      value: item,
      label: item
    })) || [],
  required: () => false,
  fieldType: () => "dropDown",
  isVisible: (loanInformation: any, loanType: any) =>
    loanInformation.selectedToorakProduct !== ToorakProductEnum.Rental &&
    loanType === "BridgeLoan",
  colSpan: 4,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const extensionOptionField = {
  fieldName: "extensionOption",
  backendKey: "extensionOptions",
  editedLabelPath: "extensionOptions",
  fieldLabel: "Extension Option (Months)",
  required: (loanStage: any) => true,
  fieldType: (loanType: string) => "number",
  colSpan: 4,
  isVisible: (loanInformation: any) => true,
  fieldComponent: NoOfPropertiesTextField,
  errorMsg: "Please Add Extension Option (Months)",
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const defaultRate = {
  fieldName: "defaultRate",
  editedLabelPath: "defaultRate",
  fieldLabel: "Default Rate (%)",
  required: (loanStage: any) => true,
  fieldType: (loanType: string) => "number",
  colSpan: 4,
  isVisible: (loanInformation: any) => true,
  fieldComponent: NoOfPropertiesTextField,
  errorMsg: "Please Add Default Rate (%)",
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const grUpExitStrategy: any = {
  fieldName: "exitStrategy",
  backendKey: "exitStrategy",
  editedLabel: "exitStrategy",
  fieldLabel: "Exit Strategy",
  required: () => false,
  fieldType: (loanType: string) => "dropDown",
  dropDownData:
    gUpFESExitStrategyOptions.map((item: any) => ({
      value: item,
      label: item
    })) || [],
  colSpan: 4,
  isVisible: (loanInformation: any) => true,
  errorMsg: "Please select value",
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const exitStrategy: any = {
  fieldName: "exitStrategy",
  backendKey: "exitStrategy",
  editedLabel: "exitStrategy",
  fieldLabel: "Exit Strategy",
  required: () => false,
  fieldType: (loanType: string) => "dropDown",
  dropDownData:
    exitStrategyDropDown.map((item: any) => ({
      value: item,
      label: item
    })) || [],
  colSpan: 4,
  isVisible: (loanInformation: any) => true,
  errorMsg: "Please select value",
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const bridgeGrUpFesExitStrategy = {
  fieldName: "exitStrategy",
  backendKey: "exitStrategy",
  editedLabel: "exitStrategy",
  fieldLabel: "Exit Strategy",
  required: () => false,
  fieldType: (loanType: string) => "dropDown",
  dropDownData:
    gUpFESExitStrategyOptions.map((item: any) => ({
      value: item,
      label: item
    })) || [],
  colSpan: 4,
  isVisible: (loanInformation: any) => true,
  errorMsg: "Please select value",
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const pledgeOfEquity = {
  fieldName: "pledgeOfEquity",
  backendKey: "pledgeOfEquity",
  editedLabel: "pledgeOfEquity",
  fieldLabel: "Pledge Of Equity",
  required: () => false,
  fieldType: (loanType: string) => "booleanDropDown",
  dropDownData: yesNoDropdown.map((item: any) => ({
    value: item,
    label: item
  })),
  colSpan: 4,
  isVisible: (loanInformation: any) => true,
  errorMsg: "Please select value",
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable,
  getValue: (loanInformation: any) => {
    return getBooleanDropDownValue(loanInformation.pledgeOfEquity, true);
  }
};

const requiredInterestReservesAmount = {
  fieldName: "requiredInterestReservesAmount",
  backendKey: "requiredInterestReservesAmount",
  fieldLabel: "Required Interest Reserves (Amount)($)",
  fieldType: (loanType: string) => "string",
  isVisible: (data: any) => true,
  required: () => false,
  colSpan: 4,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) => true,
  // rules: (inputs: any) => {
  //   const { loanEvaluationResult, loanId } = inputs;
  //   return formatValueByType(
  //     loanEvaluationResult?.loanResult &&
  //       loanEvaluationResult.loanResult[loanId]?.loanCharacterisations
  //         ?.requiredInterestReservesAmount,
  //     "currency"
  //   );
  // },
  fieldId: "requiredInterestReserves-fes",
  ruleFieldName: "requiredInterestReserves"
};

const requiredInterestReservesMonth = {
  fieldName: "requiredInterestReservesMonth",
  backendKey: "requiredInterestReservesMonth",
  fieldLabel: "Required Interest Reserves (Months)",
  required: () => false,
  fieldType: (loanType: string) => "string",
  isVisible: (data: any) => true,
  colSpan: 4,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) => true,
  // rules: (inputs: any) => {
  //   const { loanEvaluationResult, loanId } = inputs;
  //   return (
  //     loanEvaluationResult?.loanResult &&
  //     loanEvaluationResult.loanResult[loanId]?.loanCharacterisations
  //       ?.requiredInterestReservesMonth
  //   );
  // },
  fieldId: "requiredInterestReservesMonth-fes",
  ruleFieldName: "requiredInterestReservesMonth"
};

const irFundingSource = {
  fieldName: "irFundingSource",
  backendKey: "irFundingSource",
  fieldLabel: "IR funding source",
  fieldType: (loanType: string) => "dropDown",
  isVisible: (data: any) => true,
  required: () => false,
  colSpan: 4,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable,
  fieldId: "irFundingSource-fes",
  ruleFieldName: "irFundingSource",
  dropDownData: irFundingSources.map((item: any) => ({
    value: item,
    label: item
  }))
};

const isPermitApprovalsAndFullPlansInPlace = {
  fieldName: "isPermitApprovalsAndFullPlansInPlace",
  backendKey: "isPermitApprovalsAndFullPlansInPlace",
  fieldLabel: "Permit Approvals & Full Plans in Place",
  fieldType: (loanType: string) => "dropDown",
  isVisible: (data: any) => true,
  required: () => false,
  colSpan: 4,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable,
  fieldId: "isPermitApprovalsAndFullPlansInPlace-fes",
  ruleFieldName: "isPermitApprovalsAndFullPlansInPlace",
  dropDownData: yesNoDropdown.map((item: any) => ({
    value: item,
    label: item
  }))
};

const bridgeFes14Fields: any = [
  { ...primaryLoanId, required: () => false, fieldName: "primaryLoanId" },
  { ...bridgeLoanStructure, required: () => false, fieldName: "loanStructure" },
  { ...bridgeLoanPurpose, required: () => false },
  { ...cashOutFlag, fieldName: "cashoutFlag" },
  exitStrategy,
  pledgeOfEquity
];

const bridgeFesMultiFamilyFields: any = [
  { ...primaryLoanId, required: () => false, fieldName: "primaryLoanId" },
  { ...bridgeLoanStructure, required: () => false, fieldName: "loanStructure" },
  { ...bridgeLoanPurpose, required: () => false },
  { ...cashOutFlag, fieldName: "cashoutFlag" },
  {
    ...selectedRecourse,
    fieldName: "recourse",
    dropDownData: multiFamilyRecourse.map((item: any) => ({
      value: item,
      label: item
    }))
  },
  exitStrategy,
  loanTerm,
  requiredInterestReservesAmount,
  requiredInterestReservesMonth
];

const fesBorrowerFields: any = [
  { ...primaryLoanId, required: () => false, fieldName: "primaryLoanId" },
  expectedClosingDate,
  borrowerProceeds,
  numberOfProperties,
  condoEligibility,
  loanTerm,
  selectedCross,
  creditEvent
];

const postBridgeFields: any = [
  sellerName,
  { ...bridgePrimaryLoanId, editedLabelPath: "primaryLoanID" },
  rentalLoanStructure,
  bridgeLoanStructure,
  bridgeLoanPurpose,
  selectedBridgeToorakProduct,
  originationDate,
  cutOffDate,
  firstPaymentDate,
  originalMaturityDate,
  { ...selectedRecourse, required: () => true },
  { ...bridgeSelectedCross, required: () => true },
  extensionOptionField,
  numberOfProperties,
  accrualDate,
  nextPaymentDueDate,
  // defaultRate,
  bridgeFundingChannel
];

const bridgePreFields: any = [
  sellerName,
  bridgePrimaryLoanId,
  rentalLoanStructure,
  { ...bridgeLoanStructure, required: () => false },
  bridgeLoanPurpose,
  selectedBridgeToorakProduct,
  originationDate,
  { ...cutOffDate, required: (loanStage: any) => false },
  {
    ...firstPaymentDate,
    required: (data: any) => data?.selectedFundingChannel === "Table Funding"
  },
  { ...originalMaturityDate, required: () => false },
  { ...selectedRecourse, required: () => false },
  bridgeSelectedCross,
  extensionOptionField,
  numberOfProperties,
  accrualDate,
  nextPaymentDueDate,
  // defaultRate,
  bridgeFundingChannel
];

const PostDSCRFields: any = [
  sellerName,
  primaryLoanId,
  cutOffDate,
  firstPaymentDate,
  { ...dscrLoanPurpose, required: () => false },
  maturityDate,
  originationDate,
  selectedCross,
  { ...selectedRecourse, required: () => true },
  { ...selectedToorakProduct, required: () => true },
  numberOfProperties,
  { ...condoEligibility, required: () => true },
  { ...creditEvent, required: () => true },
  accrualDate,
  nextPaymentDueDate,
  fundingChannel
];

const preDSCRFields: any = [
  sellerName,
  primaryLoanId,
  cutOffDate,
  firstPaymentDate,
  { ...dscrLoanPurpose, required: () => false },
  maturityDate,
  originationDate,
  { ...selectedCross, required: () => true },
  { ...selectedRecourse, required: () => true },
  { ...selectedToorakProduct, required: () => true },
  numberOfProperties,
  { ...condoEligibility, required: () => true },
  { ...creditEvent, required: () => true },
  accrualDate,
  nextPaymentDueDate,
  { ...fundingChannel, required: () => true }
];

const getBridgeFESFields = (toorakProduct: any) => {
  if (toorakProduct === LoanSizerEnum.oneFourFamily) {
    return [...bridgeFes14Fields];
  }
  if (toorakProduct === LoanSizerEnum.multifamilyMixedUse) {
    return bridgeFesMultiFamilyFields;
  }
  return [
    { ...primaryLoanId, required: () => false, fieldName: "primaryLoanId" },
    bridgeGrUpLoanStructure,
    { ...cashOutFlag, fieldName: "cashoutFlag" },
    bridgeGrUpFesExitStrategy,
    {
      ...loanTerm,
      required: () => false,
      fieldType: () => "dropDown",
      dropDownData: gUpLoanTerm.map(({ value, label }: any) => ({
        value,
        label
      }))
    },
    irFundingSource,
    isPermitApprovalsAndFullPlansInPlace
  ];
};

export const getLoanInformationFields = (
  loanStage: string,
  loanType: string,
  loanSizerType?: any
) => {
  switch (loanStage) {
    case "fes":
      return loanType === "InvestorDSCR"
        ? fesBorrowerFields
        : getBridgeFESFields(loanSizerType);
    case "pre":
      return loanType === "InvestorDSCR" ? preDSCRFields : bridgePreFields;
    case "post":
      return loanType === "InvestorDSCR" ? PostDSCRFields : postBridgeFields;
    default:
      break;
  }
};
