import { Grid } from "@mui/material";
import React,{useState} from "react";
import { DrawInternalNotesInterface } from "../stores/drawRequest/drawRequest.interface";
import { getDrawDateCreated, getInitials } from "./AssetManagement.utils";
import { useDrawsStyles } from "./DrawRequest/Draws.styles";

export const RenderChats = ({ Chats }: { Chats: DrawInternalNotesInterface[] }) => {
  const [expandedStates, setExpandedStates] = useState<Record<number, boolean>>({});
  const classes = useDrawsStyles();

  const toggleExpanded = (index: number) => {
    setExpandedStates((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const showText = (text: string, index: number) => {
    try {
      let value = JSON.parse(text);
      const isExpanded = expandedStates[index];

      return (
        <div
          style={{
            width: '374px',
            margin: '23px 9.5px',
            padding: '15px 0.5px',
            borderRadius: '4px',
            border: 'solid 1px #8ff1ff',
            backgroundColor: '#f3ffff',
            maxHeight: '800px',
          }}
        >
          <p style={{ marginLeft: '10px', marginRight: '10px' }}>{value.msg}</p>
          <div
            style={{
              width: '373px',
              height: '27px',
              margin: '16px 0 12px',
              padding: '4px 12px 6px',
              border: 'solid 1px #a0e5e3',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div style={{ width: '20%' }}>Line Item</div>
            <div style={{ width: '40%' }}>Requested Amount</div>
            <div style={{ width: '40%' }}>Approved Amount</div>
          </div>

          {value?.dataObj.slice(0, isExpanded ? value?.dataObj.length : 2).map((item: any, idx: any) => (
            <div
              key={idx}
              style={{
                marginTop: '10px',
                marginLeft: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div style={{ width: '20%' }}>{item.lineItem}</div>
              <div style={{ width: '40%', paddingLeft: '40px' }}>${item.requestedAmount}</div>
              <div style={{ width: '40%', paddingLeft: '40px' }}>${item.approvedAmount}</div>
            </div>
          ))}
          {value?.dataObj.length > 2 && (
            <div style={{ marginTop: '10px', marginLeft: '10px' }}>
              <button onClick={() => toggleExpanded(index)}>
                {isExpanded ? 'Show less' : `+${value?.dataObj.length - 2} remaining items`}
              </button>
            </div>
          )}
        </div>
      );
    } catch (err) {
      return <>{text}</>;
    }
  };

  return (
    <>
      {Chats.map((row: DrawInternalNotesInterface, index: number) => (
        <Grid item container xs={12} className={classes.discussionsDisplay} key={index}>
          <div className={classes.userInfo} data-testid="discussion-user-info">
            <span className={classes.avatar}>{getInitials(row.userName)}</span>
            <span className={classes.personName}>{row.userName}</span>
            <span className={classes.personName}>{getDrawDateCreated(row.time)}</span>
          </div>
          <Grid item container xs={12} className={classes.comment} data-testid="discussion-chat">
            {showText(row.text, index)}
          </Grid>
        </Grid>
      ))}
    </>
  );
};
