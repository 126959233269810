/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-restricted-globals */
import moment from "moment";
import { LoanStage } from "@toorak/tc-common-fe-sdk";
import { getLoanType } from "../../config/config";
import { ToorakProductEnum } from "../../create-loan/constants/loanCreationDropDownValues";
import {
  currencyToNumber,
  formatValueByType,
  isEmptyValue
} from "../../utils/formatChecks";

export interface KeyMetricFieldType {
  fieldId: string;
  fieldLabel: string;
  fieldType: string;
  getValue: any;
  popOverData?: any;
  showInStage?: LoanStage[];
  redirectionType?: string;
}
export interface KeyMetricSectionType {
  sectionId: string;
  sectionLabel: string;
  fields: any;
}

const getAccumlatedPropertyValue = (data: any, key: string) => {
  const { propertyDetails } = data;
  let valueToReturn: any = null;
  if (propertyDetails) {
    propertyDetails.forEach((element: any) => {
      const { propertyEconomics } = element;
      if (!isEmptyValue(propertyEconomics?.[key])) {
        const cleanedValue = currencyToNumber(propertyEconomics?.[key]);
        if (valueToReturn) {
          valueToReturn += Number(cleanedValue);
        } else {
          valueToReturn = Number(cleanedValue);
        }
      }
    });
  }
  return `${valueToReturn}`;
};

export const getKeyDealMetricsFieldMap = (
  loanType: string
): KeyMetricSectionType[] => {
  switch (loanType) {
    case getLoanType[0].displayValue:
      return bridgeLoanKeyMetricSection;
    case getLoanType[1].displayValue:
      return investorDSCRLoanKeyMetricSection;
    default:
      return [];
  }
};
/** --------------------------- BridgeLoan section start ---------------------------*/
export const bridgeLoanGeneralFields: KeyMetricFieldType[] = [
  {
    fieldId: "loanId",
    fieldLabel: "Loan ID",
    fieldType: "string",
    showInStage: [LoanStage.pre, LoanStage.post],
    getValue: (data: any) => {
      const { loanDetails } = data;
      return loanDetails?.loanInfo?.primaryLoanId;
    }
  },
  {
    fieldId: "originator",
    fieldLabel: "Originator",
    fieldType: "string",
    getValue: (data: any) => {
      const { loanDetails } = data;
      return loanDetails?.originatorInfo?.accountName;
    }
  },
  {
    fieldId: "toorakProduct",
    fieldLabel: "Toorak Product",
    fieldType: "string",
    getValue: (data: any) => {
      return (
        data?.loanDetails?.loanInfo?.toorakProduct ?? ToorakProductEnum.Rehab
      );
    }
  },
  {
    fieldId: "address",
    fieldLabel: "Full Address",
    fieldType: "string",
    redirectionType: "googleMap",
    getValue: (data: any) => {
      const { propertyDetails } = data;
      if (propertyDetails) {
        const numberOfProperty = propertyDetails.length;
        if (numberOfProperty > 1) {
          return `Multiple Properties-${numberOfProperty}`;
        }
        const { propertyLocation } = propertyDetails[0];
        const { addressLine1, city, state, postalCode } = propertyLocation;
        return `${addressLine1}, ${city}, ${state}, ${postalCode}`;
      }
    },
    popOverData: (data: any) => {
      const { propertyDetails } = data;
      if (propertyDetails) {
        const numberOfProperty = propertyDetails.length;
        if (numberOfProperty <= 1) {
          return [];
        }
        const propertyAddressArray = propertyDetails.map((ele: any) => {
          const { propertyLocation } = ele;
          const { addressLine1, city, state, postalCode } = propertyLocation;
          return `${addressLine1}, ${city}, ${state}, ${postalCode}`;
        });
        return propertyAddressArray;
      }
      return [];
    }
  },
  {
    fieldId: "toorakCharacterization",
    fieldLabel: "Toorak Characterization",
    fieldType: "string",
    getValue: (data: any) => {
      const { ruleResult } = data;
      const { loanResult } = ruleResult;
      if (!loanResult) return "";
      const loanId = Object.keys(loanResult)[0];
      return (
        loanResult?.[loanId]?.loanCharacterisations?.loanCharacterisation ||
        loanResult?.[loanId]?.loanCharacterisation?.loanCharacterisation
      );
    }
  }
];
export const bridgeLoanBorrowerInfoFields: KeyMetricFieldType[] = [
  {
    fieldId: "experience",
    fieldLabel: "Experience",
    fieldType: "number",
    getValue: (data: any) => {
      const { ruleResult } = data;
      const { loanResult } = ruleResult;
      if (!loanResult) return "";
      const loanId = Object.keys(loanResult)[0];
      return loanResult?.[loanId]?.borrowerInfo?.experience;
    }
  },
  {
    fieldId: "creditScore",
    fieldLabel: "Credit Score",
    fieldType: "number",
    getValue: (data: any) => {
      const { ruleResult } = data;
      const { loanResult } = ruleResult;
      if (!loanResult) return "";
      const loanId = Object.keys(loanResult)[0];
      return loanResult?.[loanId]?.borrowerInfo?.originalCreditScoreMedian;
    }
  },
  {
    fieldId: "isForeignNational",
    fieldLabel: "Foreign National",
    fieldType: "string",
    getValue: (data: any) => {
      const { ruleResult } = data;
      const { loanResult } = ruleResult;
      if (!loanResult) return "";
      const loanId = Object.keys(loanResult)[0];
      const fn = loanResult?.[loanId]?.borrowerInfo?.isForeignNational;
      if (isEmptyValue(fn)) return null;
      const value = fn === "N" ? "No" : "Yes";
      return value;
    }
  }
];

export const bridgeLoanLoanInfoFields: KeyMetricFieldType[] = [
  {
    fieldId: "loanStructure",
    fieldLabel: "Loan Structure",
    fieldType: "string",
    getValue: (data: any) => {
      const { loanDetails } = data;
      return loanDetails?.loanInfo?.loanStructure;
    }
  },
  {
    fieldId: "loanPurpose",
    fieldLabel: "Loan Purpose",
    fieldType: "string",
    getValue: (data: any) => {
      const { loanDetails, ruleResult } = data;
      const { loanResult } = ruleResult;
      if (!loanResult) return "";
      const loanId = Object.keys(loanResult)[0];
      return (
        loanDetails?.loanInfo?.loanPurpose ??
        loanResult?.[loanId]?.loanCharacterisation?.loanPurpose ??
        loanResult?.[loanId]?.loanCharacterisations?.loanPurpose
      );
    }
  },
  {
    fieldId: "codLoan",
    fieldLabel: "COD Loan",
    fieldType: "currency",
    showInStage: [LoanStage.pre, LoanStage.post],
    getValue: (data: any) => {
      const { loanDetails } = data;
      return loanDetails?.loanEconomics?.cutOffDateLoanAmount;
    }
  },
  {
    fieldId: "codMaxLoan",
    fieldLabel: "COD Max Loan",
    fieldType: "currency",
    showInStage: [LoanStage.pre, LoanStage.post],
    getValue: (data: any) => {
      const { loanDetails } = data;
      return loanDetails?.loanEconomics?.cutOffDateMaximumLoanAmount;
    }
  },
  {
    fieldId: "requestedInitialLoan",
    fieldLabel: "Requested Initial Loan",
    fieldType: "currency",
    showInStage: [LoanStage.fes],
    getValue: (data: any) => {
      const { loanDetails } = data;
      return loanDetails?.loanEconomics?.originalLoanAmount;
    }
  },
  {
    fieldId: "requestedMaxLoan",
    fieldLabel: "Requested Max Loan",
    fieldType: "currency",
    showInStage: [LoanStage.fes],
    getValue: (data: any) => {
      const { loanDetails, loanStage } = data;
      if (loanStage === LoanStage.fes) {
        const valueToReturn =
          Number(
            currencyToNumber(loanDetails?.loanEconomics?.originalLoanAmount)
          ) + Number(currencyToNumber(loanDetails?.loanEconomics?.rehabAmount));
        return valueToReturn.toString();
      }
      return loanDetails?.loanEconomics?.cutOffDateMaximumLoanAmount;
    }
  },
  {
    fieldId: "interestReserve",
    fieldLabel: "Interest Reserve",
    fieldType: "currency",
    showInStage: [LoanStage.pre, LoanStage.post],
    getValue: (data: any) => {
      const { loanDetails } = data;
      return loanDetails?.loanEconomics?.financedInterestReserve;
    }
  },
  {
    fieldId: "interestRate",
    fieldLabel: "Interest Rate",
    showInStage: [LoanStage.pre, LoanStage.post],
    fieldType: "percentageWithout100x",
    getValue: (data: any) => {
      const { loanDetails } = data;
      return loanDetails?.loanEconomics?.interestRateAsOfCutOffDate;
    }
  },
  {
    fieldId: "cashOut",
    fieldLabel: "Cash Out",
    fieldType: "string",
    showInStage: [LoanStage.fes],
    getValue: (data: any) => {
      const { loanDetails } = data;
      return loanDetails?.loanInfo?.cashoutFlag;
    }
  },
  {
    fieldId: "cashOutAmount",
    fieldLabel: "Cash Out",
    fieldType: "currency",
    showInStage: [LoanStage.pre, LoanStage.post],
    getValue: (data: any) => {
      const { loanDetails } = data;
      return loanDetails?.loanEconomics?.cashOutAmount;
    }
  },
  {
    fieldId: "DSCRCalculation",
    fieldLabel: "DSCR Calculation",
    fieldType: "string",
    // showInStage: [LoanStage.pre, LoanStage.post],
    getValue: (data: any) => {
      const { ruleResult, loanStage } = data;
      const { loanResult } = ruleResult;
      if (!loanResult) return "";
      const loanId = Object.keys(loanResult)[0];
      return loanResult[loanId].loanEconomics?.dscrCalculation;
    }
  },
  {
    fieldId: "DSCR",
    fieldLabel: "DSCR",
    fieldType: "string",
    getValue: (data: any) => {
      const { ruleResult, loanStage } = data;
      const { loanResult } = ruleResult;
      if (!loanResult) return "";
      const loanId = Object.keys(loanResult)[0];
      // if (loanStage === LoanStage.fes) {
      //   return loanResult[loanId].loanEconomics?.dscrCalculation;
      // }
      return loanResult[loanId].loanEconomics?.dscrRequirementTest;
    }
  },
  {
    fieldId: "rentalYield",
    fieldLabel: "Rental Yield",
    fieldType: "percentage",
    showInStage: [LoanStage.fes],
    getValue: (data: any) => {
      const { ruleResult, loanStage } = data;
      const { loanResult } = ruleResult;
      if (!loanResult) return "";
      const loanId = Object.keys(loanResult)[0];
      if (loanStage === LoanStage.fes) {
        return loanResult[loanId].guidelineOutput.rentYieldCalculation;
      }
      return loanResult[loanId].loanEconomics.rentYieldCalculation;
    }
  },
  {
    fieldId: "debtYield",
    fieldLabel: "Debt Yield",
    fieldType: "percentage",
    showInStage: [LoanStage.pre, LoanStage.post],
    getValue: (data: any) => {
      const { ruleResult } = data;
      const { loanResult } = ruleResult;
      const loanId = Object.keys(loanResult)[0];
      return loanResult[loanId].multiFamilyMixedUse.actualDebtYield;
    }
  },
  {
    fieldId: "term",
    fieldLabel: "Term",
    fieldType: "string",
    showInStage: [LoanStage.pre, LoanStage.post],
    getValue: (data: any) => {
      const { ruleResult } = data;
      const { loanResult } = ruleResult;
      if (!loanResult) return "";
      const loanId = Object.keys(loanResult)[0];
      return loanResult[loanId].loanFeatures.originalTermToMaturity;
    }
  },
  {
    fieldId: "profitability",
    fieldLabel: "Profitability",
    fieldType: "percentage",
    getValue: (data: any) => {
      const { ruleResult, loanStage } = data;
      const { loanResult } = ruleResult;
      if (!loanResult) return "";
      const loanId = Object.keys(loanResult)[0];
      if (loanStage === LoanStage.fes) {
        return loanResult[loanId].guidelineOutput.profitabilityCalculation;
      }
      return loanResult[loanId].loanEconomics.profitabilityCalculation;
    }
  },
  {
    fieldId: "totalBudgetAmount",
    fieldLabel: "Total Rehab Budget",
    fieldType: "currency",
    getValue: (data: any) => {
      const { loanDetails } = data;
      return loanDetails?.loanEconomics?.totalBudgetAmount;
    }
  }
];
export const bridgeLoanPropertyInfoFields: KeyMetricFieldType[] = [
  {
    fieldId: "propertyType",
    fieldLabel: "Property Type",
    fieldType: "string",
    getValue: (data: any) => {
      const { propertyDetails } = data;
      if (propertyDetails) {
        const numberOfProperty = propertyDetails.length;
        if (numberOfProperty > 1) {
          return `Multiple Properties-${numberOfProperty}`;
        }
        const { propertyinfo } = propertyDetails[0];
        const propertyType =
          propertyinfo.propertyType === "SFR"
            ? "Single Family Detached"
            : propertyinfo.propertyType;
        return propertyType;
      }
    },
    popOverData: (data: any) => {
      const { propertyDetails } = data;
      if (propertyDetails) {
        const numberOfProperty = propertyDetails.length;
        if (numberOfProperty <= 1) {
          return [];
        }
        const propertyTypeArray = propertyDetails.map((ele: any) => {
          const { propertyinfo } = ele;
          const propertyType =
            propertyinfo.propertyType === "SFR"
              ? "Single Family Detached"
              : propertyinfo.propertyType;
          return propertyType;
        });

        return propertyTypeArray;
      }
      return [];
    }
  },
  {
    fieldId: "units",
    fieldLabel: "Units",
    fieldType: "number",
    getValue: (data: any) => {
      const { propertyDetails } = data;
      let numberOfUnits: any = null;
      if (propertyDetails) {
        propertyDetails.forEach((element: any) => {
          const { propertyinfo } = element;
          if (!isEmptyValue(propertyinfo.propertyUnits)) {
            if (numberOfUnits) {
              numberOfUnits += Number(propertyinfo.propertyUnits);
            } else {
              numberOfUnits = Number(propertyinfo.propertyUnits);
            }
          }
        });
      }
      return numberOfUnits;
    }
  },
  {
    fieldId: "asIs",
    fieldLabel: "As-Is",
    fieldType: "currency",
    getValue: (data: any) =>
      getAccumlatedPropertyValue(data, "originalAsIsAppraisalValue")
  },
  {
    fieldId: "arv",
    fieldLabel: "ARV",
    fieldType: "currency",
    getValue: (data: any) =>
      getAccumlatedPropertyValue(data, "originalAsRepairedAppraisedValue")
  },
  {
    fieldId: "ARV/As-Isperunit",
    fieldLabel: "ARV/As-Is per unit",
    fieldType: "string",
    getValue: (data: any) => {
      const { ruleResult, loanStage } = data;
      const { propertiesResults } = ruleResult;
      if (!propertiesResults) return "";
      const propertyIds = Object.keys(propertiesResults);
      if (propertyIds.length > 1) {
        return `Multiple Properties-${propertyIds.length}`;
      }
      if (loanStage === LoanStage.fes) {
        const { minimumArvPerUnit, minimumAsIsPerUnit } =
          propertiesResults[propertyIds[0]]?.guidelineOutput;
        let returnValue = "";
        if (!isEmptyValue(minimumArvPerUnit)) {
          returnValue = `ARV: ${formatValueByType(
            minimumArvPerUnit,
            "currency"
          )}`;
        } else {
          returnValue = "ARV: N/A";
        }
        if (!isEmptyValue(minimumAsIsPerUnit)) {
          returnValue += `; As-Is: ${formatValueByType(
            minimumAsIsPerUnit,
            "currency"
          )}`;
        } else {
          returnValue += "; As-Is: N/A";
        }
        return returnValue;
      }
      return formatValueByType(
        propertiesResults?.[propertyIds[0]]?.multiFamilyMixedUse?.valuePerUnit,
        "currency"
      );
    },
    popOverData: (data: any) => {
      const { ruleResult, loanStage } = data;
      const { propertiesResults } = ruleResult;
      if (!propertiesResults) return "";
      const propertyIds = Object.keys(propertiesResults);
      if (propertyIds.length <= 1) {
        return [];
      }
      let valueToReturn = [];
      valueToReturn = propertyIds.map((propertyId: any) => {
        if (loanStage === LoanStage.fes) {
          const { minimumArvPerUnit, minimumAsIsPerUnit } =
            propertiesResults[propertyId].guidelineOutput;
          let returnValue = "";
          if (!isEmptyValue(minimumArvPerUnit)) {
            returnValue = `ARV: ${formatValueByType(
              minimumArvPerUnit,
              "currency"
            )}`;
          } else {
            returnValue = "ARV: N/A";
          }
          if (!isEmptyValue(minimumAsIsPerUnit)) {
            returnValue += `; As-Is: ${formatValueByType(
              minimumAsIsPerUnit,
              "currency"
            )}`;
          } else {
            returnValue += "; As-Is: N/A";
          }
          return returnValue;
        }
        return isEmptyValue(
          propertiesResults?.[propertyId]?.multiFamilyMixedUse?.valuePerUnit
        )
          ? "N/A"
          : formatValueByType(
              propertiesResults?.[propertyId]?.multiFamilyMixedUse
                ?.valuePerUnit,
              "currency"
            );
      });
      return valueToReturn;
    }
  },
  {
    fieldId: "purchasePrice",
    fieldLabel: "Purchase Price",
    fieldType: "currency",
    getValue: (data: any) => getAccumlatedPropertyValue(data, "purchasePrice")
  },
  {
    fieldId: "costBasis", // TO-DO: change: after multi family its fes calculation
    fieldLabel: "Cost Basis",
    fieldType: "currency",
    getValue: (data: any) => getAccumlatedPropertyValue(data, "costBasis")
  },
  {
    fieldId: "financedBudget",
    fieldLabel: "Financed Budget Amount",
    fieldType: "currency",
    getValue: (data: any) => {
      const { loanStage, loanDetails } = data;
      if (loanStage === LoanStage.fes) {
        return loanDetails?.loanEconomics?.rehabAmount;
      }
      return loanDetails?.loanEconomics?.financedBudgetAmount;
    }
  },
  {
    fieldId: "assignmentFees",
    fieldLabel: "Assignment Fee",
    fieldType: "currency",
    showInStage: [LoanStage.fes],
    getValue: (data: any) => getAccumlatedPropertyValue(data, "assignmentFees")
  }
];
export const bridgeLoanZillowDataFields: KeyMetricFieldType[] = [
  {
    fieldId: "medianValue",
    fieldLabel: "Median Value",
    fieldType: "currency",
    getValue: (data: any) => {
      const { ruleResult } = data;
      const { propertiesResults } = ruleResult;
      if (!propertiesResults) return "";
      const firstProperty = Object.keys(propertiesResults)[0];
      return (
        propertiesResults?.[firstProperty]?.loanFeatures
          ?.medianHomePriceValue ||
        propertiesResults?.[firstProperty]?.loanFeatures?.medianValue
      );
    }
  },
  {
    fieldId: "HPA",
    fieldLabel: "HPA",
    fieldType: "percentage",
    getValue: (data: any) => {
      const { ruleResult } = data;
      const { propertiesResults } = ruleResult;
      if (!propertiesResults) return "";
      const firstProperty = Object.keys(propertiesResults)[0];
      return (
        propertiesResults?.[firstProperty]?.loanFeatures?.medianHomeHpa ||
        propertiesResults?.[firstProperty]?.loanFeatures?.ltmHpa
      );
    }
  },
  {
    fieldId: "DOM",
    fieldLabel: "DOM",
    fieldType: "number",
    getValue: (data: any) => {
      const { ruleResult, loanStage } = data;
      const { propertiesResults } = ruleResult;
      if (!propertiesResults) return "";
      const firstProperty = Object.keys(propertiesResults)[0];
      if (loanStage === LoanStage.fes) {
        return propertiesResults?.[firstProperty]?.loanFeatures?.daysOnMarket;
      }
      return propertiesResults?.[firstProperty]?.loanFeatures?.daysOnMarket;
    }
  }
];
export const bridgeLoanRequestedRatiosFields: KeyMetricFieldType[] = [
  {
    fieldId: "LTVAs-Is",
    fieldLabel: "LTV As-Is",
    fieldType: "percentageTwoDecimal",
    getValue: (data: any) => {
      const { ruleResult } = data;
      const { loanResult } = ruleResult;
      if (!loanResult) return "";
      const loanId = Object.keys(loanResult)[0];
      return loanResult[loanId].loanFeatures.originalAsIsLtv;
    }
  },
  {
    fieldId: "LTVARV",
    fieldLabel: "LTV ARV",
    fieldType: "percentageTwoDecimal",
    getValue: (data: any) => {
      const { ruleResult } = data;
      const { loanResult } = ruleResult;
      if (!loanResult) return "";
      const loanId = Object.keys(loanResult)[0];
      return (
        loanResult?.[loanId]?.loanFeatures?.originalAsIsRepairedLtv ||
        loanResult?.[loanId]?.loanFeatures?.originalAsRepairedLtv
      );
    }
  },
  {
    fieldId: "LTC",
    fieldLabel: "LTC",
    fieldType: "percentageTwoDecimal",
    getValue: (data: any) => {
      const { ruleResult } = data;
      const { loanResult } = ruleResult;
      if (!loanResult) return "";
      const loanId = Object.keys(loanResult)[0];
      return loanResult[loanId].loanFeatures.originalLtc;
    }
  }
];
export const bridgeLoanMaxGuidelineLeverageFields: KeyMetricFieldType[] = [
  {
    fieldId: "LTVAs-Is",
    fieldLabel: "LTV As-Is",
    fieldType: "percentage",
    getValue: (data: any) => {
      const { ruleResult } = data;
      const { loanResult } = ruleResult;
      if (!loanResult) return "";
      const loanId = Object.keys(loanResult)[0];
      return (
        loanResult?.[loanId]?.maxLeverage?.maxLTVAsIs ||
        loanResult?.[loanId]?.maxLeverageTest?.maxLTVAsIs
      );
    }
  },
  {
    fieldId: "LTVARV",
    fieldLabel: "LTV ARV",
    fieldType: "percentage",
    getValue: (data: any) => {
      const { ruleResult } = data;
      const { loanResult } = ruleResult;
      if (!loanResult) return "";
      const loanId = Object.keys(loanResult)[0];
      return (
        loanResult?.[loanId]?.maxLeverage?.maxLTVARV ||
        loanResult?.[loanId]?.maxLeverageTest?.maxLTVARV
      );
    }
  },
  {
    fieldId: "LTC",
    fieldLabel: "LTC",
    fieldType: "percentage",
    getValue: (data: any) => {
      const { ruleResult } = data;
      const { loanResult } = ruleResult;
      if (!loanResult) return "";
      const loanId = Object.keys(loanResult)[0];
      return (
        loanResult?.[loanId]?.maxLeverage?.maxLTCOne ||
        loanResult?.[loanId]?.maxLeverageTest?.maxLTCOne
      );
    }
  }
];
export const bridgeLoanAdjustedRatioFields: KeyMetricFieldType[] = [
  {
    fieldId: "LTVAs-Is",
    fieldLabel: "LTV As-Is",
    fieldType: "percentage",
    getValue: (data: any) => {
      const { ruleResult, loanStage } = data;
      const { loanResult } = ruleResult;
      if (!loanResult) return "";
      const loanId = Object.keys(loanResult)[0];
      if (loanStage === LoanStage.fes) {
        return loanResult[loanId].finalLeverage.afterAdjustmentMaxLTVAsIs;
      }
      return loanResult[loanId].toorakProceedsTest.afterAdjustmentMaxLTVAsIs;
    }
  },
  {
    fieldId: "LTVARV",
    fieldLabel: "LTV ARV",
    fieldType: "percentage",
    getValue: (data: any) => {
      const { ruleResult, loanStage } = data;
      const { loanResult } = ruleResult;
      if (!loanResult) return "";
      const loanId = Object.keys(loanResult)[0];
      if (loanStage === LoanStage.fes) {
        return loanResult[loanId].finalLeverage.afterAdjustmentMaxARV;
      }
      return loanResult[loanId].toorakProceedsTest.afterAdjustmentMaxARV;
    }
  },
  {
    fieldId: "LTC",
    fieldLabel: "LTC",
    fieldType: "percentage",
    getValue: (data: any) => {
      const { ruleResult, loanStage } = data;
      const { loanResult } = ruleResult;
      if (!loanResult) return "";
      const loanId = Object.keys(loanResult)[0];
      if (loanStage === LoanStage.fes) {
        return loanResult[loanId].finalLeverage.afterAdjustmentMaxLTC;
      }
      return loanResult[loanId].toorakProceedsTest.afterAdjustmentMaxLTC;
    }
  }
];
export const bridgeLoanKeyMetricSection: KeyMetricSectionType[] = [
  {
    sectionId: "general",
    sectionLabel: "General",
    fields: bridgeLoanGeneralFields
  },
  {
    sectionId: "borrowerInfo",
    sectionLabel: "Borrower Information",
    fields: bridgeLoanBorrowerInfoFields
  },
  {
    sectionId: "loanInfo",
    sectionLabel: "Loan Information",
    fields: bridgeLoanLoanInfoFields
  },
  {
    sectionId: "propertyInfo",
    sectionLabel: "Property Information",
    fields: bridgeLoanPropertyInfoFields
  },
  {
    sectionId: "zillowData",
    sectionLabel: "Zillow Data",
    fields: bridgeLoanZillowDataFields
  },
  {
    sectionId: "requestedRatios",
    sectionLabel: "Requested Ratios",
    fields: bridgeLoanRequestedRatiosFields
  },
  {
    sectionId: "maxGuidelineLeverage",
    sectionLabel: "Maximum Guideline Leverage",
    fields: bridgeLoanMaxGuidelineLeverageFields
  },
  {
    sectionId: "adjustedRatios",
    sectionLabel: "Adjusted Ratios",
    fields: bridgeLoanAdjustedRatioFields
  }
];
/** --------------------------- BridgeLoan section end --------------------------- */

/** --------------------------- Investor DSCR section start ---------------------------*/
export const investorDscrLoanInfoFields: KeyMetricFieldType[] = [
  {
    fieldId: "loanId",
    fieldLabel: "Loan ID",
    fieldType: "string",
    showInStage: [LoanStage.pre, LoanStage.post],
    getValue: (data: any) => {
      const { loanDetails } = data;
      return loanDetails?.loanInfo?.primaryLoanId;
    }
  },
  {
    fieldId: "originator",
    fieldLabel: "Originator",
    fieldType: "string",
    getValue: (data: any) => {
      const { loanDetails } = data;
      return loanDetails?.originatorInfo?.accountName;
    }
  },
  {
    fieldId: "toorakProduct",
    fieldLabel: "Toorak Product",
    fieldType: "string",
    getValue: (data: any) => {
      return "30 Yr Rental";
    }
  },
  {
    fieldId: "toorakCharacterization",
    fieldLabel: "Toorak Characterization",
    fieldType: "string",
    getValue: (data: any) => {
      const { loanDetails, loanStage, ruleResult } = data;

      if (loanStage === LoanStage.fes) {
        const { loanResult } = ruleResult;
        if (!loanResult) return "";
        const loanId = Object.keys(loanResult)[0];
        return loanResult?.[loanId]?.loanCharacterization
          .finalLoanCharacterization;
      }
      return loanDetails?.loanInfo?.loanPurpose;
    }
  }
];
export const investorDscrBorrowerInfoFields: KeyMetricFieldType[] = [
  {
    fieldId: "creditScore",
    fieldLabel: "Credit Score",
    fieldType: "number",
    getValue: (data: any) => {
      const { ruleResult } = data;
      const { loanResult } = ruleResult;
      if (!loanResult) return "";
      const loanId = Object.keys(loanResult)[0];
      return loanResult[loanId]?.borrowerInfo?.originalCreditScoreMedian;
    }
  }
];
export const investorDscrLoanEconomicsField: KeyMetricFieldType[] = [
  {
    fieldId: "originalLoanAmount",
    fieldLabel: "Original Loan Amount",
    fieldType: "currency",
    getValue: (data: any) => {
      const { loanDetails } = data;
      return loanDetails?.loanEconomics?.originalLoanAmount;
    }
  },
  {
    fieldId: "interestRate",
    fieldLabel: "Interest Rate",
    fieldType: "percentageWithout100x",
    getValue: (data: any) => {
      const { loanDetails } = data;
      return loanDetails?.loanEconomics?.interestRateAsOfCutOffDate;
    }
  },
  {
    fieldId: "originalTermToMaturity",
    fieldLabel: "Original Term to Maturity(Months)",
    fieldType: "number",
    getValue: (data: any) => {
      const { ruleResult, loanStage } = data;
      const { loanResult } = ruleResult;
      if (!loanResult) return "";
      const loanId = Object.keys(loanResult)[0];
      if (loanStage === LoanStage.fes) {
        const { loanDetails } = data;
        return loanDetails?.loanInfo?.loanTermMonthly;
      }
      return loanResult[loanId].loanFeatures.originalTermToMaturity;
    }
  },
  {
    fieldId: "ioPeriods",
    fieldLabel: "IO Period(Months)",
    fieldType: "number",
    getValue: (data: any) => {
      const { loanDetails } = data;
      return loanDetails?.loanEconomics?.interestOnlyPeriod;
    }
  },
  {
    fieldId: "prepaymentPenaltyMonths",
    fieldLabel: "Prepayment Penalty(Months)",
    fieldType: "number",
    getValue: (data: any) => {
      const { ruleResult, loanDetails, loanStage } = data;
      if (loanStage === LoanStage.fes) {
        return loanDetails?.loanEconomics?.prepaymentPenaltyMonths;
      }
      const { loanResult } = ruleResult;
      if (!loanResult) return "";
      const loanId = Object.keys(loanResult)[0];
      return loanResult[loanId].loanEconomics.prePaymentPenaltyMonths;
    }
  },
  {
    fieldId: "cashOutAmount",
    fieldLabel: "Cash Out Amount",
    fieldType: "currency",
    showInStage: [LoanStage.pre, LoanStage.post],
    getValue: (data: any) => {
      const { loanDetails } = data;
      return loanDetails?.loanEconomics?.cashOutAmount;
    }
  },
  {
    fieldId: "DSCR",
    fieldLabel: "DSCR",
    fieldType: "number",
    getValue: (data: any) => {
      const { ruleResult } = data;
      const { loanResult } = ruleResult;
      if (!loanResult) return "";
      const loanId = Object.keys(loanResult)[0];
      return loanResult[loanId].loanEconomics.dscr;
    }
  },
  {
    fieldId: "grossPotentialRent",
    fieldLabel: "Monthly Property Gross Rent",
    fieldType: "currency",
    getValue: (data: any) => {
      const { ruleResult } = data;
      const { loanResult } = ruleResult || {};
      if (!loanResult) return "";
      const loanId = Object.keys(loanResult).length
        ? Object.keys(loanResult)[0]
        : "";
      return loanId
        ? loanResult[loanId].loanEconomics?.aggregateMonthlyPropertyGrossRent
        : "";
    }
  },
  {
    fieldId: "monthlyInterest",
    fieldLabel: "Monthly Interest",
    fieldType: "currency",
    getValue: (data: any) => {
      const { ruleResult } = data;
      const { loanResult } = ruleResult || {};
      if (!loanResult) return "";
      const loanId = Object.keys(loanResult).length
        ? Object.keys(loanResult)[0]
        : "";
      return loanId ? loanResult[loanId].loanEconomics?.monthlyInterest : "";
    }
  },
  {
    fieldId: "monthlyPrincipalDuringAmortization",
    fieldLabel: "Monthly Principal During Amortization",
    fieldType: "currency",
    getValue: (data: any) => {
      const { ruleResult } = data;
      const { loanResult } = ruleResult || {};
      if (!loanResult) return "";
      const loanId = Object.keys(loanResult).length
        ? Object.keys(loanResult)[0]
        : "";
      return loanId
        ? loanResult[loanId].loanEconomics?.monthlyPrincipalDuringAmortization
        : "";
    }
  },
  {
    fieldId: "aggregateAnnualPropertyTaxes",
    fieldLabel: "Monthly Taxes",
    fieldType: "currency",
    getValue: (data: any) => {
      const { ruleResult } = data;
      const { loanResult } = ruleResult || {};
      if (!loanResult) return "";
      const loanId = Object.keys(loanResult).length
        ? Object.keys(loanResult)[0]
        : "";
      return loanId
        ? loanResult[loanId].loanEconomics?.aggregateAnnualPropertyTaxes
        : "";
    }
  },
  {
    fieldId: "aggregateMonthlyAnnualHazardInsurance",
    fieldLabel: "Monthly Insurance",
    fieldType: "currency",
    getValue: (data: any) => {
      const { ruleResult } = data;
      const { loanResult } = ruleResult || {};
      if (!loanResult) return "";
      const loanId = Object.keys(loanResult).length
        ? Object.keys(loanResult)[0]
        : "";
      return loanId
        ? loanResult[loanId].loanEconomics
            ?.aggregateMonthlyAnnualHazardInsurance
        : "";
    }
  },
  {
    fieldId: "aggregateMonthlyAnnualFloodInsurance",
    fieldLabel: "Monthly Flood Insurance",
    fieldType: "currency",
    getValue: (data: any) => {
      const { ruleResult } = data;
      const { loanResult } = ruleResult || {};
      if (!loanResult) return "";
      const loanId = Object.keys(loanResult).length
        ? Object.keys(loanResult)[0]
        : "";
      return loanId
        ? loanResult[loanId].loanEconomics?.aggregateMonthlyAnnualFloodInsurance
        : "";
    }
  },
  {
    fieldId: "aggregateMonthlyAnnualHOA",
    fieldLabel: "Monthly HOA",
    fieldType: "currency",
    getValue: (data: any) => {
      const { ruleResult } = data;
      const { loanResult } = ruleResult || {};
      if (!loanResult) return "";
      const loanId = Object.keys(loanResult).length
        ? Object.keys(loanResult)[0]
        : "";
      return loanId
        ? loanResult[loanId].loanEconomics?.aggregateMonthlyAnnualHOA
        : "";
    }
  },
  {
    fieldId: "rateType",
    fieldLabel: "Rate Type",
    fieldType: "string",
    getValue: (data: any) => {
      const { loanDetails } = data;
      return loanDetails?.loanEconomics?.rateType;
    }
  },
  {
    fieldId: "requiredReserves",
    fieldLabel: "Required reserves",
    showInStage: [LoanStage.fes],
    fieldType: "currency",
    getValue: (data: any) => {
      const { ruleResult } = data;
      const { loanResult } = ruleResult;
      if (!loanResult) return "";
      const loanId = Object.keys(loanResult)[0];
      return loanResult[loanId]?.loanEconomics?.minimumBorrowerLiquidity;
    }
  }
];
export const investorDscrPropertyInfoFields: KeyMetricFieldType[] = [
  {
    fieldId: "address",
    fieldLabel: "Address",
    fieldType: "string",
    redirectionType: "googleMap",
    getValue: (data: any) => {
      const { propertyDetails } = data;
      if (propertyDetails) {
        const numberOfProperty = propertyDetails.length;
        if (numberOfProperty > 1) {
          return `Multiple Properties-${numberOfProperty}`;
        }
        const { propertyLocation } = propertyDetails[0];
        const { addressLine1, city, state, postalCode } = propertyLocation;
        return `${addressLine1}, ${city}, ${state}, ${postalCode}`;
      }
    },
    popOverData: (data: any) => {
      const { propertyDetails } = data;
      if (propertyDetails) {
        const numberOfProperty = propertyDetails.length;
        if (numberOfProperty <= 1) {
          return [];
        }
        const propertyAddressArray = propertyDetails.map((ele: any) => {
          const { propertyLocation } = ele;
          const { addressLine1, city, state, postalCode } = propertyLocation;
          return `${addressLine1}, ${city}, ${state}, ${postalCode}`;
        });
        return propertyAddressArray;
      }
      return [];
    }
  },
  {
    fieldId: "propertyType",
    fieldLabel: "Property Type",
    fieldType: "string",
    getValue: (data: any) => {
      const { propertyDetails } = data;
      if (propertyDetails) {
        const numberOfProperty = propertyDetails.length;
        if (numberOfProperty > 1) {
          return `Multiple Properties-${numberOfProperty}`;
        }
        const { propertyinfo } = propertyDetails[0];
        const propertyType =
          propertyinfo.propertyType === "SFR"
            ? "Single Family Detached"
            : propertyinfo.propertyType;
        return propertyType;
      }
    },
    popOverData: (data: any) => {
      const { propertyDetails } = data;
      if (propertyDetails) {
        const numberOfProperty = propertyDetails.length;
        if (numberOfProperty <= 1) {
          return [];
        }
        return propertyDetails.map((ele: any) => {
          const { propertyinfo } = ele;
          return propertyinfo.propertyType === "SFR"
            ? "Single Family Detached"
            : propertyinfo.propertyType;
        });
      }
      return [];
    }
  },
  {
    fieldId: "units",
    fieldLabel: "Units",
    fieldType: "number",
    getValue: (data: any) => {
      const { propertyDetails } = data;
      let numberOfUnits: any = null;
      if (propertyDetails) {
        propertyDetails.forEach((element: any) => {
          const { propertyinfo } = element;
          if (!isEmptyValue(propertyinfo.propertyUnits)) {
            if (numberOfUnits) {
              numberOfUnits += Number(propertyinfo.propertyUnits);
            } else {
              numberOfUnits = Number(propertyinfo.propertyUnits);
            }
          }
        });
      }
      return numberOfUnits;
    }
  },
  {
    fieldId: "asIs-original",
    fieldLabel: 'Original "As Is" Appraisal Value',
    fieldType: "currency",
    getValue: (data: any) =>
      getAccumlatedPropertyValue(data, "originalAsIsAppraisalValue")
  },
  {
    fieldId: "percentOccupied",
    fieldLabel: "Occupancy %",
    fieldType: "percentage",
    getValue: (data: any) => {
      const { ruleResult } = data;
      const { propertiesResults } = ruleResult;
      if (!propertiesResults) return "";
      let valueToReturn: any;
      const propertyKeys = Object.keys(propertiesResults);
      propertyKeys.forEach((key) => {
        const percentOccupied = Number(
          propertiesResults?.[key]?.loanEconomics?.percentOccupied
        );
        if (!isNaN(percentOccupied)) {
          if (!isEmptyValue(valueToReturn)) {
            valueToReturn += percentOccupied;
          } else {
            valueToReturn = percentOccupied;
          }
        }
      });
      return valueToReturn
        ? valueToReturn / propertyKeys.length
        : valueToReturn;
    }
  }
];
export const investorDscrPropertyEconomicsFields: KeyMetricFieldType[] = [
  {
    fieldId: "purchasePrice",
    fieldLabel: "Purchase Price",
    fieldType: "currency",
    getValue: (data: any) => getAccumlatedPropertyValue(data, "purchasePrice")
  },
  {
    fieldId: "costBasis",
    fieldLabel: "Cost Basis",
    fieldType: "currency",
    showInStage: [LoanStage.pre, LoanStage.post],
    getValue: (data: any) => getAccumlatedPropertyValue(data, "costBasis")
  }
];
export const investorDSCRActualRatiosFields: KeyMetricFieldType[] = [
  {
    fieldId: "Original-As Is-LTV",
    fieldLabel: 'Original "As Is" LTV',
    fieldType: "percentageTwoDecimal",
    getValue: (data: any) => {
      const { ruleResult } = data;
      const { loanResult } = ruleResult;
      if (!loanResult) return "";
      const loanId = Object.keys(loanResult)[0];
      return loanResult[loanId].loanFeatures.originalAsIsLtv;
    }
  },
  {
    fieldId: "LTC",
    fieldLabel: "Original LTC/LTP",
    fieldType: "percentageTwoDecimal",
    getValue: (data: any) => {
      const { ruleResult } = data;
      const { loanResult } = ruleResult;
      if (!loanResult) return "";
      const loanId = Object.keys(loanResult)[0];
      return loanResult[loanId].loanFeatures.originalLtc;
    }
  }
];

export const investorDscrMaxGuidelineLeverageFields: KeyMetricFieldType[] = [
  {
    fieldId: "MaxAs-IsLTV",
    fieldLabel: "Max As-Is LTV",
    fieldType: "percentage",
    getValue: (data: any) => {
      const { ruleResult } = data;
      const { loanResult } = ruleResult;
      if (!loanResult) return "";
      const loanId = Object.keys(loanResult)[0];
      return loanResult[loanId].maxLeverage.maxAsIsLTV;
    }
  },
  {
    fieldId: "LTC",
    fieldLabel: "Max LTC",
    fieldType: "percentage",
    getValue: (data: any) => {
      const { ruleResult } = data;
      const { loanResult } = ruleResult;
      if (!loanResult) return "";
      const loanId = Object.keys(loanResult)[0];
      return loanResult[loanId].maxLeverage.maxLTC;
    }
  }
];
export const investorDscrNetLeverageFields: KeyMetricFieldType[] = [
  {
    fieldId: "afterAdjustment-LTVAs-Is",
    fieldLabel: "Afer Adjustment: Max As-Is LTV",
    fieldType: "percentage",
    getValue: (data: any) => {
      const { ruleResult, loanStage } = data;
      const { loanResult } = ruleResult;
      if (!loanResult) return "";
      const loanId = Object.keys(loanResult)[0];
      if (loanStage === LoanStage.fes) {
        return loanResult[loanId].toorakProceeds.toorakAdjustedAsIsLtv;
      }
      return loanResult[loanId].toorakProceeds.afterAdjustmentMaxLTVAsIs;
    }
  },
  {
    fieldId: "afterAdjustment-LTC",
    fieldLabel: "Afer Adjustment: Max LTC",
    fieldType: "percentage",
    getValue: (data: any) => {
      const { ruleResult, loanStage } = data;
      const { loanResult } = ruleResult;
      if (!loanResult) return "";
      const loanId = Object.keys(loanResult)[0];
      if (loanStage === LoanStage.fes) {
        return loanResult[loanId].toorakProceeds.toorakAdjustedAsIsLtc;
      }
      return loanResult[loanId].toorakProceeds.afterAdjustmentMaxLTC;
    }
  }
];

const getRateLockExpiryDate = (
  initialDate: string,
  finalExtensionDays: number
) => {
  if (isEmptyValue(initialDate)) return "NA";
  const expiry = moment
    .utc(initialDate)
    .subtract(parseInt(`${finalExtensionDays}`, 10), "days");
  const expiryDate = `${expiry.format("MMM DD, YYYY")}`;
  return `${expiryDate}`;
};

export const investorDSCRRateLockFields: KeyMetricFieldType[] = [
  {
    fieldId: "rate-sheet-date",
    fieldLabel: "Rate Sheet Date",
    fieldType: "date",
    getValue: (data: any) => {
      const { loanDetails } = data;
      const rateSheetDate =
        loanDetails?.loanDetailId?.loanConfigId?.rateLockedRateSheet
          ?.replace(/\D/g, "")
          .trim();
      return loanDetails?.loanDetailId?.loanConfigId?.rateLockedRateSheet
        ? moment(rateSheetDate, "DD-MM-YY").format("MMM DD, YYYY")
        : "-";
    }
  },
  {
    fieldId: "initial-lock-date",
    fieldLabel: "Initial Lock Date",
    fieldType: "date",
    getValue: (data: any) => {
      const { loanDetails } = data;
      return loanDetails?.loanDetailId?.loanConfigId?.rateLockedDate
        ? moment(
            loanDetails?.loanDetailId?.loanConfigId?.rateLockedDate
          ).format("MMM DD, YYYY")
        : "-";
    }
  },
  {
    fieldId: "initial-lock-term",
    fieldLabel: "Initial Lock Term",
    fieldType: "string",
    getValue: (data: any) => {
      const { loanDetails } = data;
      return loanDetails?.loanDetailId?.loanConfigId?.rateLockPeriod
        ? `${loanDetails?.loanDetailId?.loanConfigId?.rateLockPeriod} Days`
        : "-";
    }
  },
  {
    fieldId: "initial-lock-term-cost",
    fieldLabel: "Initial Lock Term Cost",
    fieldType: "number",
    getValue: (data: any) => {
      const { ruleResult } = data;
      const { loanResult } = ruleResult;
      if (!loanResult) return "";
      const loanId = Object.keys(loanResult)[0];
      return loanResult[loanId].loanPricing?.rateLockAdjustment ?? "-";
    }
  },
  {
    fieldId: "initial-rate-lock-expiry-date",
    fieldLabel: "Initial Rate Lock Expiry Date",
    fieldType: "date",
    getValue: (data: any) => {
      const { loanDetails } = data;
      let finalCount = 0;
      if (Array.isArray(loanDetails.loanDetailId?.rateLockExtensions)) {
        loanDetails.loanDetailId?.rateLockExtensions?.map((extension: any) => {
          finalCount += extension?.extensionDays;
        });
        return loanDetails?.rateLockExpiryDate
          ? getRateLockExpiryDate(loanDetails?.rateLockExpiryDate, finalCount)
          : "-";
      }
    }
  },
  {
    fieldId: "is-extension",
    fieldLabel: "Is Extension",
    fieldType: "string",
    getValue: (data: any) => {
      const { loanDetails } = data;
      return Array.isArray(loanDetails.loanDetailId?.rateLockExtensions) &&
        loanDetails.loanDetailId?.rateLockExtensions?.length
        ? "Yes"
        : "No";
    }
  },
  {
    fieldId: "extension-days",
    fieldLabel: "Extension Term",
    fieldType: "string",
    getValue: (data: any) => {
      const { loanDetails } = data;
      let finalCount = 0;
      if (Array.isArray(loanDetails.loanDetailId?.rateLockExtensions)) {
        loanDetails.loanDetailId?.rateLockExtensions?.map((extension: any) => {
          finalCount += extension?.extensionDays;
        });
      }
      return finalCount ? `${finalCount} Days` : "-";
    }
  },
  {
    fieldId: "extension-cost",
    fieldLabel: "Extension Cost",
    fieldType: "number",
    getValue: (data: any) => {
      const { ruleResult } = data;
      const { loanResult } = ruleResult;
      if (!loanResult) return "";
      const loanId = Object.keys(loanResult)[0];
      return loanResult[loanId].loanPricing?.rateLockExtensionAdjustment ?? "-";
    }
  },
  {
    fieldId: "final-lock-term",
    fieldLabel: "Final Lock Term",
    fieldType: "string",
    getValue: (data: any) => {
      const { loanDetails } = data;
      let finalCount =
        loanDetails?.loanDetailId?.loanConfigId?.rateLockPeriod ?? 0;
      if (Array.isArray(loanDetails.loanDetailId?.rateLockExtensions)) {
        loanDetails.loanDetailId?.rateLockExtensions?.map((extension: any) => {
          finalCount += extension?.extensionDays;
        });
      }
      return finalCount ? `${finalCount} Days` : "-";
    }
  },
  {
    fieldId: "final-cost",
    fieldLabel: "Final Lock Term Cost",
    fieldType: "number",
    getValue: (data: any) => {
      const { ruleResult } = data;
      const { loanResult } = ruleResult;
      if (!loanResult) return "";
      const loanId = Object.keys(loanResult)[0];
      const result =
        parseFloat(
          loanResult[loanId]?.loanPricing?.rateLockExtensionAdjustment ?? "0"
        ) +
        parseFloat(loanResult[loanId]?.loanPricing?.rateLockAdjustment ?? "0");
      return result ?? "-";
    }
  },
  {
    fieldId: "current-lock-expiry-date",
    fieldLabel: "Current Lock Expiry Date",
    fieldType: "date",
    getValue: (data: any) => {
      const { loanDetails } = data;
      return loanDetails?.rateLockExpiryDate
        ? moment(loanDetails?.rateLockExpiryDate).format("MMM DD, YYYY")
        : "-";
    }
  },
  {
    fieldId: "final-price",
    fieldLabel: "Final Price",
    fieldType: "number",
    getValue: (data: any) => {
      const { ruleResult } = data;
      const { loanResult } = ruleResult;
      if (!loanResult) return "";
      const loanId = Object.keys(loanResult)[0];
      return loanResult[loanId].loanPricing?.finalToorakPrice ?? "NA";
    }
  }
];

export const investorDSCRLoanKeyMetricSection: KeyMetricSectionType[] = [
  {
    sectionId: "loanInformation",
    sectionLabel: "Loan Information",
    fields: investorDscrLoanInfoFields
  },
  {
    sectionId: "borrowerInfo",
    sectionLabel: "Borrower Information",
    fields: investorDscrBorrowerInfoFields
  },
  {
    sectionId: "loanEconomics",
    sectionLabel: "Loan Economics",
    fields: investorDscrLoanEconomicsField
  },
  {
    sectionId: "propertyInfo",
    sectionLabel: "Property Information",
    fields: investorDscrPropertyInfoFields
  },
  {
    sectionId: "propertyEconomics",
    sectionLabel: "Property Economics",
    fields: investorDscrPropertyEconomicsFields
  },
  {
    sectionId: "actualRatios",
    sectionLabel: "Actual Ratios",
    fields: investorDSCRActualRatiosFields
  },
  {
    sectionId: "maxGuidelineLeverage",
    sectionLabel: "Max Leverages",
    fields: investorDscrMaxGuidelineLeverageFields
  },
  {
    sectionId: "netLeverage",
    sectionLabel: "Net Leverages",
    fields: investorDscrNetLeverageFields
  },
  {
    sectionId: "rateLockFields",
    sectionLabel: "Lock Info",
    fields: investorDSCRRateLockFields
  }
];
/** --------------------------- Investor DSCR section end ---------------------------*/
