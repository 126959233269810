/* eslint-disable no-nested-ternary */
/* eslint-disable no-plusplus */
/* eslint-disable react/destructuring-assignment */
import { Box, Grid, IconButton, Tooltip } from "@mui/material";
import React, { useState, useMemo } from "react";
import HistoryIcon from "@mui/icons-material/HistoryOutlined";
import moment from "moment";
import { LoanStatusEnum } from "@toorak/tc-common-util-sdk";
import LaunchIcon from "@mui/icons-material/Launch";
import { useDispatch } from "react-redux";
import { PopOverInfo } from "../../../key-deal-metrics/PopOverInfo";

import { isLATUser, isLoanOnHold } from "../../../utils/AccessManagement";
import {
  formatNumberToCommaSeparated,
  regex,
  sanitizeCurrency
} from "../../../utils/formatChecks";
import {
  // GridTooltip,
  MultiplePropertyToolTip,
  LoanIDTooltip,
  OnHoldTooltip,
  InquiryStatusTooltip,
  InfoOnlyTooltip,
  CountTooltip
} from "../../../utils/Tooltip";
import { getConfig, getLoanType } from "../../../config/config";
import { commonStatusFormatter } from "../utils/commonStatusFormatter";
import { LoanHistory } from "../utils/LoanHistory";
import { LoanProcessTypeEnum, TABLE_FUNDING, ADVANCE_FUNDING } from "../../../utils/constants";
import { genMaskedOrgBundleStatus } from "../../purchase-and-settlements/constants";
import {
  checkIfStringStartsWith,
  checkIfToorvalLoan,
  isString
} from "../../../utils/utils";
import { notToBeCountedDocs, tabStatus } from "../../ttf-review/constants";
import { getDocumentsAndInternalTags } from "../../../network/apiService";
import { NoDocumentsModal } from "../../documents/NoDocumentsModal";
import {
  hideLoader,
  showLoader
} from "../../../stores/loaderAndException/loaderAndException.action";
import { getBusinessDatesCount } from "../../../masterView/common";
import { taskNames } from "../../../stores/tasks/Tasks.action";
import { budgetReviewStatusEnum } from "../../../ilp/list-view/BudgetTaskManagement";

const idToTextTransformationRequiredKeys = [
  "loanCreatedBy",
  "assignedTo",
  "dueDiligencePartyId",
  "reviewedBy",
  "submittedBy",
  "updatedBy"
];

const month = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec"
];

export const roleOfLATAssigneeMap: any = {
  LAT_IR: "Initial Reviewer",
  LAT_QC: "QC",
  LAT_APPROVER: "Approver",
  LAT_INQUIRY: "Inquiry",
  LAT_ADMIN: "Admin",
  LAT_VM: "VM"
};

export const RouteToTaskPage = (props: {
  value: string;
  loanType: string;
  loanId: string;
  loanStage: string;
  route: string;
  loanState: LoanStatusEnum;
  ttfVersion?: string;
}) => {
  const { value, loanType, loanStage, loanId, route, ttfVersion } = props;

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
    useState<boolean>(false);

  const dispatch = useDispatch();

  const handleCloseWarningDialog = () => {
    setIsConfirmationModalOpen(false);
  };

  const shouldShowLaunchIcon = useMemo(() => {
    return (
      value &&
      ((route === "budgetReview" &&
        ![budgetReviewStatusEnum.LoanSubmitted, "Not Applicable"].includes(
          value as tabStatus
        ) &&
        loanType === "BridgeLoan") ||
        (ttfVersion === "New" &&
          (route === "dataReconciliation" ||
            route.includes(taskNames.TAG) ||
            (route.includes("documentReview") &&
              [tabStatus.COMPLETED, tabStatus.INPROGRESS].includes(
                value as tabStatus
              )))))
    );
  }, [value, route, ttfVersion, loanType]);

  const openDocReview = () => {
    if (route.includes(taskNames.TAG) && value === tabStatus.PENDING) {
      dispatch(showLoader());
      const documentCalls = [
        getDocumentsAndInternalTags(loanId, `${loanStage}-prePurchase`),
        getDocumentsAndInternalTags(loanId, `${loanStage}-postClose`)
      ];
      Promise.all(documentCalls).then((responseArr: any) => {
        dispatch(hideLoader());
        let docCount =
          responseArr[0].data.filter(
            (val: any) => !checkIfStringStartsWith(notToBeCountedDocs, val.name)
          )?.length ?? 0;
        docCount +=
          responseArr[1].data.filter(
            (val: any) => !checkIfStringStartsWith(notToBeCountedDocs, val.name)
          )?.length ?? 0;
        if (docCount) {
          window.open(
            `${
              getConfig().redirectUrl
            }internal/loan/createLoan/${loanType}/${loanStage}/${loanId}/${route}`
          );
        } else {
          setIsConfirmationModalOpen(true);
        }
      });
    } else {
      window.open(
        `${
          getConfig().redirectUrl
        }internal/loan/createLoan/${loanType}/${loanStage}/${loanId}/${route}`
      );
    }
  };
  return (
    <>
      <NoDocumentsModal
        isConfirmationModalOpen={isConfirmationModalOpen}
        handleCloseWarningDialog={handleCloseWarningDialog}
      />
      {value}
      {shouldShowLaunchIcon && (
        <LaunchIcon
          style={{
            margin: "10px",
            color: "black",
            display: "flex",
            cursor: "pointer"
          }}
          onClick={openDocReview}
        />
      )}
    </>
  );
};

export const statusFormatter = (props: any, classes: any) => {
  return (
    <>
      <StatusFormatter classes={classes} props={props} />
    </>
  );
};
const StatusFormatter = (prop: any) => {
  const [showHistory, setShowHistory] = useState(false);
  const { props, classes } = prop;
  return (
    <>
      <div className={classes.statusContainer}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className={classes.statusText}>
            {commonStatusFormatter(props?.row)}
          </div>
          <InfoOnlyTooltip title="Loan History" arrow placement="right">
            <span>
              <IconButton
                aria-label="actions"
                component="button"
                aria-haspopup="true"
                onClick={() => {
                  setShowHistory(true);
                }}
                // className={classes.history}
                style={{ width: "30px", height: "30px", padding: "5px" }}
                size="large"
              >
                <HistoryIcon fontSize="large" />
              </IconButton>
            </span>
          </InfoOnlyTooltip>
        </div>
        {props?.row?.status === "Purchase Initiated" ? (
          <div className={classes.bundleStatusText}>
            {genMaskedOrgBundleStatus(props?.row?.bundle?.bundleStatus)}
          </div>
        ) : null}
      </div>
      {showHistory && (
        <LoanHistory
          loanId={props?.row?.toorakLoanId}
          originatorId={props?.row?.loanInfo?.primaryLoanId}
          onClose={setShowHistory}
          isLoanTableFunding={
            props?.row?.loanDetailId?.fundingType === TABLE_FUNDING
          }
        />
      )}
    </>
  );
};

export const AgeingFormatter = (value: any, classes: any) => {
  const age = value ? getBusinessDatesCount(value) : NaN;
  let bgColor = "#f75777";
  if (age <= 1) {
    bgColor = "#50d69c";
  } else if (age <= 3) {
    bgColor = "#e89014";
  }

  if (isNaN(age)) {
    return <></>;
  }
  return (
    <>
      <span
        className={classes.dot}
        style={{ backgroundColor: bgColor, marginRight: 5 }}
      ></span>
      <span>{age}</span>
    </>
  );
  // let date;
  // const { status, loanCreateDate, bundleCreatedDate, ageing } =
  //   props?.row || {};

  // const transformedStatus = status?.toUpperCase();
  // if (!["PURCHASED", "PURCHASE INITIATED"].includes(transformedStatus)) {
  //   if (loanCreateDate) {
  //     date = new Date(loanCreateDate);
  //   }
  // } else if (bundleCreatedDate) {
  //   date = new Date(bundleCreatedDate);
  // }

  // const formattedDate =
  //   date === undefined
  //     ? "NA"
  //     : `${month[date.getMonth()]} ${`0${date.getDate()}`.slice(
  //         -2
  //       )}, ${date.getFullYear()}`;

  // const ageingFile =
  //   ageing <= 1 ? "group-101@2x" : ageing > 3 ? "group-11@2x" : "group-13@2x";

  // const statusToBeShown =
  //   transformedStatus === "EXCEL ERROR" ||
  //   (transformedStatus === "SUBMIT" && !props?.row?.toorval)
  //     ? "DATA ENTRY"
  //     : transformedStatus === "PURCHASED" && !isLATUser()
  //     ? "FUNDED"
  //     : transformedStatus === "DUE DILIGENCE REVIEW"
  //     ? "REVIEW IN PROGRESS"
  //     : transformedStatus;

  // return (
  //   <div className={classes.ageingContainer}>
  //     <div className="loan-table-tooltip" style={{ padding: 5 }}>
  //       <GridTooltip
  //         imageFile={ageingFile}
  //         date={formattedDate}
  //         status={statusToBeShown}
  //         days={props?.row.ageing}
  //       />
  //     </div>
  //   </div>
  // );
};

export const multiplePropertyFormatter = (props: any, classes: any) => {
  const { properties } = props?.row;
  let multipleProperty;
  if (properties?.length > 1) {
    multipleProperty = `[ Multiple properties - ${properties.length} ]`;
  }
  return (
    <MultiplePropertyToolTip
      spanClass={
        props?.column?.idx === 5
          ? classes.defaulttextWithMargin
          : classes.defaulttext
      }
      properties={properties}
      multipleProperty={multipleProperty}
      columnKey={props?.key}
    />
  );
};

export const percentageOccupied = (props: any, classes: any) => {
  const { properties, result, loanType } = props?.row;
  let propertiesData = result?.propertiesResults;
  let mainKey = "loanEconomics";
  let subKey = "percentOccupied";
  let formatterType = "percentage";
  if (loanType === "Bridge Loan") {
    propertiesData = properties;
    mainKey = "propertyinfo";
    subKey = "occupiedPercentage";
    formatterType = "percentageWithout100x";
  }
  let multipleProperty;
  if (propertiesData?.length > 1) {
    multipleProperty = `[ Multiple properties - ${propertiesData.length} ]`;
  }
  return (
    <MultiplePropertyToolTip
      spanClass={
        props?.column?.idx === 5
          ? classes.defaulttextWithMargin
          : classes.defaulttext
      }
      properties={propertiesData || []}
      multipleProperty={multipleProperty}
      columnKey={props?.key}
      mainKey={mainKey}
      subKey={subKey}
      formatterType={formatterType}
    />
  );
};

export const loanIdFormatter = (props: any, classes: any) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      className={classes.loanIdWrap}
      onContextMenu={(event: any) => {
        event.preventDefault();
      }}
    >
      <div className={classes.loanIdSection}>
        <LoanIDTooltip parentProps={props} />
      </div>
      {isLoanOnHold(props?.row?.onHoldBy) && (
        <OnHoldTooltip parentProps={props?.row} />
      )}
    </Box>
  );
};

export const inquiryStatusFormatter = (props: any, classes: any) => {
  return (
    <div className={classes.ageingContainer}>
      <div className="loan-table-tooltip">
        <InquiryStatusTooltip parentProps={props} />
      </div>
    </div>
  );
};

export const dateFormatter = (props: any, classes: any) => {
  if (!props?.row[props?.key]) {
    return <span className={classes.defaulttext} />;
  }
  const date = new Date(props?.row[props?.key]);
  const formattedDate = `${month[date.getMonth()]} ${`0${date.getDate()}`.slice(
    -2
  )}, ${date.getFullYear()}`;
  return (
    <span title={formattedDate} className={classes.defaulttext}>
      {formattedDate}
    </span>
  );
};

export const interactionFormatter = (props: any) => {
  const {
    currentPendingExternalEnquiryCount,
    currentPendingExternalExceptionCount,
    currentPendingExternalWaiverCount,
    //currentPendingAllEnquiryCount,
    currentPendingAllExceptionCount,
    currentPendingAllWaiverCount,
    count
  } = props.activeInteractions;
  const { loanDetailId, loanStageRaw } = props;
  const getItems = (
    enquiryCount: number,
    exceptionCount: number,
    waiverCount: number
  ) => {
    const totalCount = enquiryCount + exceptionCount + waiverCount;
    const openInteraction: any = [];
    const validItems: any = [];
    if (totalCount > 0) {
      if (exceptionCount > 0) validItems.push("exceptionCount");
      if (enquiryCount > 0) validItems.push("enquiryCount");
      if (waiverCount > 0) validItems.push("waiverCount");
    }
    for (let i = 0; i < validItems.length; i++) {
      let tempObj: any = {};
      if (validItems[i] === "exceptionCount") {
        tempObj.option = "Open Conditions";
        tempObj.count = exceptionCount;
      } else if (validItems[i] === "enquiryCount") {
        tempObj.option = "Open Inquiries";
        tempObj.count = enquiryCount;
      } else {
        tempObj.option = "Open Waivers";
        tempObj.count = waiverCount;
      }
      openInteraction.push(tempObj);
    }
    return openInteraction;
  };

  if (isLATUser()) {
    let totalLATCount =
      currentPendingExternalEnquiryCount +
      currentPendingAllExceptionCount +
      currentPendingAllWaiverCount;
    if (totalLATCount !== 0 && count !== 0) {
      const countInteractionsArr = getItems(
        currentPendingExternalEnquiryCount,
        currentPendingAllExceptionCount,
        currentPendingAllWaiverCount
      );
      return (
        <CountTooltip
          totalCount={totalLATCount}
          count={count}
          pendingObj={countInteractionsArr}
          detailViewStyle={true}
          loanId={loanDetailId.loanId}
          loanStage={loanStageRaw}
          originatorPartyId={loanDetailId.originatorPartyId}
        />
      );
    }
    return <></>;
  }

  const totalOrgCount =
    currentPendingExternalEnquiryCount +
    currentPendingExternalExceptionCount +
    currentPendingExternalWaiverCount;
  if (totalOrgCount !== 0 && count !== 0) {
    const countInteractionsArr = getItems(
      0,
      currentPendingExternalEnquiryCount + currentPendingExternalExceptionCount,
      currentPendingExternalWaiverCount
    );
    return (
      <CountTooltip
        totalCount={totalOrgCount}
        count={count}
        pendingObj={countInteractionsArr}
        detailViewStyle={true}
        loanId={loanDetailId.loanId}
        loanStage={loanStageRaw}
        originatorPartyId={loanDetailId.originatorPartyId}
      />
    );
  }
  return <></>;
};

export const utcDateFormatter = (props: any, classes: any) => {
  if (!props?.row[props?.key]) {
    return <span className={classes.defaulttext} />;
  }
  const date = moment.utc(props?.row[props?.key]);
  const formattedDate = `${month[date.month()]} ${`0${date.date()}`.slice(
    -2
  )}, ${date.year()}`;
  return (
    <span title={formattedDate} className={classes.defaulttext}>
      {formattedDate}
    </span>
  );
};

export const LeftAlignedText = (props: any, classes: any, partyIds: any) => {
  const doesRequireTransformation = idToTextTransformationRequiredKeys.includes(
    props?.key
  );
  const key = props.row[props.key];
  const textClass =
    props?.column?.idx === 5
      ? classes.defaulttextWithMargin
      : classes.defaulttext;
  const displayText = doesRequireTransformation
    ? partyIds?.[key]
      ? partyIds[key]
      : regex("uuid").test(key)
      ? `UnIdentified User`
      : key
    : props.key === "roleOfAssignee" && roleOfLATAssigneeMap[key]
    ? roleOfLATAssigneeMap[key]
    : key;
  return (
    <span className={textClass} title={displayText}>
      {displayText}
    </span>
  );
};

export const LeftAlignedTextWithFullWord = (
  props: any,
  classes: any,
  partyIds: any
) => {
  const doesRequireTransformation = idToTextTransformationRequiredKeys.includes(
    props?.key
  );
  const key = props.row[props.key];
  const textClass =
    props?.column?.idx === 5
      ? classes.defaulttextWithMarginFullWord
      : classes.defaulttextFullWord;
  const displayText = doesRequireTransformation
    ? partyIds?.[key]
      ? partyIds[key]
      : regex("uuid").test(key)
      ? `UnIdentified User`
      : key
    : props.key === "roleOfAssignee" && roleOfLATAssigneeMap[key]
    ? roleOfLATAssigneeMap[key]
    : key;
  return (
    <span className={textClass} title={displayText}>
      {displayText}
    </span>
  );
};

export const getCharacterization = (props: any, classes: any) => {
  let characterization = props.row.loanTypeName.includes("30 Year")
    ? props.row.toorakLoanType
      ? props.row.toorakLoanType
      : ""
    : props.row.toorakLoanTypeRehab
    ? props.row.toorakLoanTypeRehab
    : "";
  if (characterization.indexOf("null") > -1) {
    characterization = characterization.split("null").join("");
  }
  return (
    <span
      className={
        props?.column?.idx === 5
          ? classes.defaulttextWithMargin
          : classes.defaulttext
      }
      title={characterization}
    >
      {characterization}
    </span>
  );
};

export const guarantorFormatter = (props: any, classes: any) => {
  let displayData;
  const loanUserMapData = props?.row.loanUserMap || [];
  const guarantorsData = props?.row.guarantors || [];
  if (loanUserMapData.length > 0 && guarantorsData.length > 0) {
    const primaryGuarantorData = loanUserMapData.filter(function (el: any) {
      return el.loanUserType === "Guarantor" && el.isPrimary === true;
    });
    if (primaryGuarantorData.length) {
      const guarantorSpecificData = props.row.guarantors.filter(function (
        el: any
      ) {
        return el.partyId === primaryGuarantorData[0].partyId;
      });
      const guarantor = guarantorSpecificData[0][props.key];
      const primaryGuarantorVal = primaryGuarantorData[0][props.key];
      displayData =
        props.key !== "ownershipOfEntity"
          ? ![undefined, null, ""].includes(guarantor)
            ? guarantor
            : ""
          : ![undefined, null, ""].includes(primaryGuarantorVal)
          ? `${primaryGuarantorVal}${
              primaryGuarantorVal.toString().indexOf(".") === -1 ? ".00" : ""
            }${primaryGuarantorVal.toString().indexOf("%") === -1 ? "%" : ""}`
          : "";
    }
    const primaryGuarantor = loanUserMapData.filter(function (el: any) {
      return el.loanUserType === "Guarantor" && el.isPrimary === true;
    });
    const primaryGuarantorId = primaryGuarantor
      ? primaryGuarantor[0]?.partyId
      : "";
    const nonPrimaryGuarantorData = loanUserMapData.filter(function (el: any) {
      return el.loanUserType === "Guarantor" && el.isPrimary === false;
    });
    if (
      nonPrimaryGuarantorData.length &&
      props.key === "additionalGuarantorName"
    ) {
      const guarantorSpecificData = props.row.guarantors.filter(function (
        el: any
      ) {
        return el.partyId !== primaryGuarantorId;
      });
      const data: any = [];
      guarantorSpecificData.forEach((value: any, index: any) => {
        if (value.firstName && value.lastName) {
          data.push(
            value.firstName.concat(
              " ",
              value.lastName,
              index !== guarantorSpecificData.length - 1 ? "," : ""
            )
          );
        }
      });
      displayData =
        data.length !== 0 ? data.filter(Boolean).join(" ") : undefined;
    }
  }
  return (
    <span
      className={
        props?.column?.idx === 5
          ? classes.defaulttextWithMargin
          : classes.defaulttext
      }
      title={!displayData ? "" : displayData}
      style={{
        float: props?.key === "ownershipOfEntity" ? "right" : "left"
      }}
    >
      {!displayData ? "" : displayData}
    </span>
  );
};

export const propertFormatter = (
  props: any,
  classes: any,
  isDraw?: boolean
) => {
  const { properties: rowProperties, toorval, loanLandmarks } = props?.row;
  const properties = rowProperties ? [...rowProperties] : [];
  const isToorvalLoan = loanLandmarks?.some(checkIfToorvalLoan);
  if (
    toorval &&
    toorval?.address !== "" &&
    isToorvalLoan &&
    properties.length === 0
  )
    properties.push({
      propertyLocation: {
        addressLine1: toorval.address,
        ...toorval,
        loanPropertyOrder: 1
      }
    });
  const duplicateProperty = JSON.parse(JSON.stringify(properties));
  if (!duplicateProperty) return;
  duplicateProperty?.sort(function (a: any, b: any) {
    return a.loanPropertyOrder - b.loanPropertyOrder;
  });
  let multipleProperty;
  let combinedAddress;
  if (duplicateProperty.length && duplicateProperty.length > 1) {
    multipleProperty = `Multiple properties - ${duplicateProperty.length}`;
  }
  if (duplicateProperty.length === 1) {
    combinedAddress = isDraw
      ? duplicateProperty[0]
      : [
          duplicateProperty[0].propertyLocation?.addressLine1,
          duplicateProperty[0].propertyLocation?.addressLine2,
          duplicateProperty[0].propertyLocation?.addressLine3,
          duplicateProperty[0].propertyLocation?.city,
          duplicateProperty[0].propertyLocation?.state,
          duplicateProperty[0].propertyLocation?.country,
          duplicateProperty[0].propertyLocation?.postalCode
        ]
          .filter(Boolean)
          .join(", ");
  }
  let arr: any = [];
  if (duplicateProperty.length > 0 && !isDraw) {
    duplicateProperty.forEach((item: any) => {
      const { addressLine1, state, city, postalCode, country } =
        item.propertyLocation;
      const fullAddress = [addressLine1, city, state, country, postalCode]
        .filter(Boolean)
        .join(", ");
      arr.push(fullAddress);
    });
  }
  arr = isDraw ? duplicateProperty : arr;
  return (
    <Grid container alignItems="center">
      <Grid
        item
        className={
          props?.column?.idx === 5
            ? classes.defaulttextWithMargin
            : classes.defaultTextProperty
        }
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap"
        }}
      >
        <span title={combinedAddress}>
          {properties?.length > 1
            ? multipleProperty
            : properties.length === 1
            ? combinedAddress
            : ""}
        </span>
      </Grid>
      <Grid item style={{ display: "flex", paddingLeft: "3px" }}>
        {properties && arr?.length > 1 ? (
          <PopOverInfo popOverData={arr} />
        ) : null}
      </Grid>
    </Grid>
  );
};

export const userFormatter = (props: any, classes: any) => {
  const originatorData = props?.row.originatorUser;
  let originatorUserName;
  if (originatorData) {
    if (originatorData?.partyType === "account") {
      originatorUserName = originatorData.accountName;
    }
    if (originatorData?.partyType === "person") {
      originatorUserName = originatorData?.firstName.concat(
        " ",
        originatorData?.lastName
      );
    }
  }
  return (
    <span
      className={
        props?.column?.idx === 5
          ? classes.defaulttextWithMargin
          : classes.defaulttext
      }
      title={originatorUserName || ""}
    >
      {originatorUserName || ""}
    </span>
  );
};

export const loanTypeFormatter = (props: any, classes: any) => {
  let displayValue = props?.row?.actualLoanType
    ? props.row.actualLoanType.split("L")[0].includes("30")
      ? props.row.actualLoanType
          .split("L")[0]
          .replace("30 Year", getLoanType[1].name)
      : props.row.actualLoanType.split("L")[0]
    : "";
  if (props.row.toorakProduct) {
    if (props.row.toorakProduct.includes("30")) {
      displayValue = `${displayValue} - ${props.row.toorakProduct.replace(
        "30 Year",
        getLoanType[1].name
      )}`;
    } else {
      displayValue = `${displayValue} - ${props.row.toorakProduct}`;
    }
  }
  const showDiv =
    [ADVANCE_FUNDING, TABLE_FUNDING].includes(
      props.row?.loanDetailId?.fundingType
    ) ||
    props.row?.loanDetailId?.isWarehouseFunded ||
    props.row?.loanProcessType === LoanProcessTypeEnum.Express ||
    props.row?.loanProcessType === LoanProcessTypeEnum.Premier;
  return (
    <>
      <div
        className={
          props?.column?.idx === 5
            ? classes.defaulttextWithMargin
            : classes.defaulttext
        }
        title={displayValue || ""}
      >
        <span>{displayValue}</span>
        {showDiv && (
          <div className={classes.loanTypeTags} style={{ padding: "5px" }}>
            {props.row?.loanDetailId?.fundingType === TABLE_FUNDING && (
              <Tooltip
                classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
                placement="right"
                arrow
                title="Table Fund Loan"
              >
                <span className={classes.fundingTag}>TF</span>
              </Tooltip>
            )}
            {props.row?.loanDetailId?.isWarehouseFunded && (
              <Tooltip
                classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
                placement="right"
                arrow
                title="Warehouse Funded Loan"
              >
                <span className={classes.fundingTag}>WH</span>
              </Tooltip>
            )}
            {props.row?.loanDetailId?.fundingType === ADVANCE_FUNDING && (
              <Tooltip
                classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
                placement="right"
                arrow
                title="Advance Funded Loan"
              >
                <span className={classes.fundingTag}>AF</span>
              </Tooltip>
            )}
            {props.row?.loanProcessType === LoanProcessTypeEnum.Express && (
              <Tooltip
                classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
                placement="right"
                arrow
                title="Express Loan"
              >
                <span className={classes.expressTag}>EX</span>
              </Tooltip>
            )}
            {props.row?.loanProcessType === LoanProcessTypeEnum.Premier && (
              <Tooltip
                classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
                placement="right"
                arrow
                title="Premier Loan"
              >
                <span className={classes.premierTag}>PR</span>
              </Tooltip>
            )}
          </div>
        )}
      </div>
    </>
  );
};
export const appendDollarSymbol = (amount: any) => {
  return `$${amount}`;
};

export const aggregateAmountFormatter = (props: any, classes: any) => {
  const { properties } = props?.row;
  let aggregateProperty: number = 0;
  let commaSeparatedAggregateProperty: any;
  if (props.row[props.key]) {
    aggregateProperty = props.row[props.key];
    return appendDollarSymbol(formatNumberToCommaSeparated(aggregateProperty));
  }
  if (properties.length) {
    if (properties.length > 1) {
      for (let i = 0; i < properties.length; i++) {
        const res1 =
          typeof properties[i].propertyEconomics[props.key] === "string"
            ? Number(
                properties[i].propertyEconomics[props.key].replace(/,/g, "")
              )
            : 0;
        aggregateProperty += res1;
      }
    } else {
      aggregateProperty =
        typeof properties[0]?.propertyEconomics[props.key] === "string"
          ? Number(properties[0].propertyEconomics[props.key].replace(/,/g, ""))
          : 0;
    }
    commaSeparatedAggregateProperty =
      formatNumberToCommaSeparated(aggregateProperty);
    commaSeparatedAggregateProperty =
      commaSeparatedAggregateProperty !== undefined
        ? appendDollarSymbol(commaSeparatedAggregateProperty)
        : "";
  }

  return (
    <MultiplePropertyToolTip
      spanClass={
        props?.column?.idx === 5
          ? classes.defaulttextWithMargin
          : classes.defaulttext
      }
      properties={properties}
      multipleProperty={commaSeparatedAggregateProperty}
      columnKey={props?.key}
    />
  );
};

export const aggregateNumberFormatter = (props: any, classes: any) => {
  const { properties } = props?.row;
  let aggregateProperty: any = 0;
  if (props.row[props.key]) {
    aggregateProperty = props.row[props.key];
    return formatNumberToCommaSeparated(aggregateProperty);
  }
  if (properties.length && aggregateProperty === 0) {
    if (properties.length > 1) {
      for (let i = 0; i < properties.length; i++) {
        const res1 = properties[i].propertyinfo[props.key]
          ? properties[i].propertyinfo[props.key]
          : 0;
        aggregateProperty += res1;
      }
    } else {
      aggregateProperty = properties?.[0]?.propertyinfo?.[props.key]
        ? properties[0]?.propertyinfo?.[props.key]
        : 0;
    }
    aggregateProperty = formatNumberToCommaSeparated(aggregateProperty);
  }

  return (
    <MultiplePropertyToolTip
      spanClass={
        props?.column?.idx === 5
          ? classes.defaulttextWithMargin
          : classes.defaulttext
      }
      properties={properties}
      multipleProperty={aggregateProperty}
      columnKey={props?.key}
    />
  );
};

export const amountFormatter = (props: any, classes: any) => {
  const data = props.row[props.key];
  let valueString = data?.toString();
  if (isString(valueString) && valueString && !valueString.match(/[^$,.\d]/)) {
    valueString = sanitizeCurrency(valueString);
  }
  return (
    <span title={valueString} className={classes.defaulttextAmount}>
      {valueString}
    </span>
  );
};

export const percentFormatter = (
  props: any,
  classes: any,
  multiplyBy100?: boolean
) => {
  const data = props?.row?.[props?.key];
  let valueString = data?.toString();
  if (![null, undefined, ""].includes(data) && !valueString.match(/[^%,.\d]/)) {
    const value = multiplyBy100
      ? (Number(data) * 100).toFixed(4)
      : Number(data).toFixed(4);
    const paddedUnit = valueString.indexOf("%") === -1 ? "%" : "";
    valueString = `${value}${paddedUnit}`;
  }
  return (
    <span title={valueString} className={classes.defaulttextAmount}>
      {valueString}
    </span>
  );
};

export const rightAlignedText = (props: any, classes: any) => {
  const data = props?.row?.[props?.key];
  return (
    <span title={data} className={classes.defaulttextAmount}>
      {data}
    </span>
  );
};

export const aggregateAppraisalNumberFormatter = (props: any, classes: any) => {
  const formattedValue = formatNumberToCommaSeparated(props?.row?.[props?.key]);

  return (
    <span title={formattedValue} className={classes.defaulttextAmount}>
      {formattedValue}
    </span>
  );
};
