/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useEffect, lazy } from "react";
import "./App.css";
import "react-date-range/dist/styles.css"; // react-date-range main css file
import "react-date-range/dist/theme/default.css"; // react-date-range theme css file
import "./DateRange.css";
import { MuiTheme, GlobalCss } from "@toorak/tc-common-fe-sdk";
import { Provider } from "react-redux";
// import { Switch,  Redirect } from "react-router-dom";
import {
  IInactivityConfig,
  InactivityCountdownTimer
} from "inactivity-countdown-timer";
// import { createBrowserHistory } from "history";
import {
  Routes,
  Route,
  BrowserRouter,
  // Redirect,
  Navigate
} from "react-router-dom";
import {
  CssBaseline,
  ThemeProvider,
  Theme,
  StyledEngineProvider
} from "@mui/material";
import StylesProvider from "@mui/styles/StylesProvider";
import createGenerateClassName from "@mui/styles/createGenerateClassName";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { getCookie, removeCookie, setCookie } from "./utils/cookies";
import { rootStore } from "./stores/rootReducer";
import { SuspenseWithFallBack } from "./ui-components/SuspenseWithFallBack";
import Lists from "./standaloneDocuments/components/lists/lists.component";
// import { CreditEvaluationWrapper } from "./credit-evaluation/CreditEvaluationWrapper";
import { DeleteLoan } from "./create-loan/admin-screen/deleteLoan";
import { UserCreation } from "./create-loan/admin-screen/userCreation";
import { getConfig } from "./config/config";
import { Interceptor } from "./Interceptors/Interceptor";
import { AuthCallbackPage } from "./login/authentication";
import { DocusignSuccess } from "./create-loan/purchase-and-settlements/components/DocusignSuccess";
import { CreateDraw } from "./assetManagement/DrawRequest/CreateDraw";
import { BulkDrawDetails } from "./assetManagement/DrawRequest/BulkDraw";
import ProvisionView from "./assetManagement/DrawRequest/Provision/ProvisionView";
import AddProvisionView from "./assetManagement/DrawRequest/Provision/AddProvisionView";
import { ServicerOriginatorMapping } from "./assetManagement/ServicerOnboarding/OriginatorServicerMapping/ServicerOriginatorMapping";
import { InspectionReviewWrapper } from "./inspection-review/InspectionReviewWrapper";
import { InternalBudgetReview } from "./budget-review/InternalBudgetReview";
// import { UnderMaintanencePage } from "./underMaintanencePage";
// import { InspectionReview } from "./inspection-review/InspectionReview";
// import { InternalBudgetReview } from "./budget-review/InternalBudgetReview";
// import { DisplayNote } from "./create-loan/createLoanHeaders/DisplayNote";

const OnboardingDocumentReviewWrapper = lazy(() =>
  import(
    "./assetManagement/ServicerOnboarding/OnboardingDocumentReviewWrapper"
  ).then((module) => ({
    default: module.OnboardingDocumentReviewWrapper
  }))
);

const Loader = lazy(() =>
  import("./create-loan/Loader").then((module) => ({ default: module.Loader }))
);
const WaiverView = lazy(() =>
  import("./create-loan/waiver-form/WaiverView").then((module) => ({
    default: module.WaiverView
  }))
);
const LoginLogoutCallback = lazy(() =>
  import("./login/LoginLogoutCallback").then((module) => ({
    default: module.LoginLogoutCallback
  }))
);

const WaiverReRequest = lazy(() =>
  import("./create-loan/waiver-form/WaiverReRequest").then((module) => ({
    default: module.WaiverReRequest
  }))
);
const ScenarioViewDetails = lazy(() =>
  import("./create-loan/scenario/ScenarioViewDetails").then((module) => ({
    default: module.ScenarioViewDetails
  }))
);

const ILPHome = lazy(() =>
  import("./ilp/list-view/ILPHome").then((module) => ({
    default: module.ILPHome
  }))
);

const MultiAppHome = lazy(() =>
  import("./home/MultiAppHome").then((module) => ({
    default: module.MultiAppHome
  }))
);
const HelpMenuWrapper = lazy(() =>
  import("./helpMenu/HelpMenuWrapper").then((module) => ({
    default: module.HelpMenuWrapper
  }))
);
const Footer = lazy(() =>
  import("./create-loan/Footer").then((module) => ({ default: module.Footer }))
);
const CreateLoanRedirector = lazy(() =>
  import("./create-loan/CreateLoanRedirector").then((module) => ({
    default: module.CreateLoanRedirector
  }))
);

const SOPurchasedLoan = lazy(() =>
  import(
    "./assetManagement/ServicerOnboarding/LoanDetails/SOPurchasedLoan"
  ).then((module) => ({
    default: module.SOPurchasedLoan
  }))
);

const PurchasedLoansDetailsProvider = lazy(() =>
  import(
    "./assetManagement/PurchasedLoansWHIntegration/PurchasedLoanDetails/PurchasedLoansDetailsProvider"
  ).then((module) => ({
    default: module.PurchasedLoansDetailsProvider
  }))
);

const PurchasedDocumentReviewProvider = lazy(() =>
  import(
    "./assetManagement/PurchasedLoansWHIntegration/PurchasedDocumentReview/PurchasedDocumentReviewProvider"
  ).then((module) => ({
    default: module.PurchasedDocumentReviewProvider
  }))
);

const DocumentReviewRedirector = lazy(() =>
  import("./create-loan/ttf-review/document-review/DocumentReviewWrapper").then(
    (module) => ({
      default: module.DocumentReviewWrapper
    })
  )
);

const DrawDocumentReviewWrapper = lazy(() =>
  import(
    "./assetManagement/DrawRequest/DrawDocTagging/DrawDocsReviewWrapper"
  ).then((module) => ({
    default: module.DrawDocsReviewWrapper
  }))
);

const OpenDocumentInNewTabRedirector = lazy(() =>
  import(
    "./create-loan/ttf-review/document-review/DocumentViewerInNewTab"
  ).then((module) => ({
    default: module.DocumentViewerInNewTab
  }))
);

const OpenBudgetDocumentInNewTabRedirector = lazy(() =>
  import("./inspection-review/DocumentsInNewTab").then((module) => ({
    default: module.DocumentViewerInNewTab
  }))
);

const dataReconciliationRedirector = lazy(() =>
  import(
    "./create-loan/ttf-review/data-reconciliation/DataReconciliation"
  ).then((module) => ({
    default: module.DataReconciliation
  }))
);

const KeyDealMetricsDetails = lazy(() =>
  import("./masterView/KeyDealMetricsDetails").then((module) => ({
    default: module.KeyDealMetricsDetails
  }))
);

// const DrawDetails = lazy(() =>
//   import("./assetManagement/DrawRequest/DrawDetails").then(module => ({
//     default: module.DrawDetails
//   }))
// );

const CreateLoanEnquiry = lazy(() =>
  import("./create-loan/exceptions-and-enquiries/CreateLoanEnquiry").then(
    (module) => ({ default: module.CreateLoanEnquiry })
  )
);
const Dashboard = lazy(() =>
  import("./create-loan/dashboard/dashboard").then((module) => ({
    default: module.Dashboard
  }))
);
const Notification = lazy(() =>
  import("./notifications/NotificationSettings").then((module) => ({
    default: module.NotificationSettings
  }))
);
const CreateNewScenario = lazy(() =>
  import("./create-loan/scenario/CreateNewScenario").then((module) => ({
    default: module.CreateNewScenario
  }))
);
const Home = lazy(() =>
  import("./create-loan/home").then((module) => ({ default: module.Home }))
);
const WaiverRequest = lazy(() =>
  import("./create-loan/waiver-form/WaiverRequest").then((module) => ({
    default: module.WaiverRequest
  }))
);
const CreateLoanType = lazy(() =>
  import("./create-loan/CreateLoanType").then((module) => ({
    default: module.CreateLoanType
  }))
);
const UiComponents = lazy(() =>
  import("./ui-components/ui-components").then((module) => ({
    default: module.UiComponents
  }))
);
const CreateNewDiscussion = lazy(() =>
  import("./create-loan/discussions/CreateNewDiscussion").then((module) => ({
    default: module.CreateNewDiscussion
  }))
);
const DiscussionDetailView = lazy(() =>
  import("./create-loan/discussions/DiscussionDetailView").then((module) => ({
    default: module.DiscussionDetailView
  }))
);
const ViewDetailsInquiry = lazy(() =>
  import("./create-loan/exceptions-and-enquiries/ViewDetailsInquiry").then(
    (module) => ({ default: module.ViewDetailsInquiry })
  )
);
const ViewDetails = lazy(() =>
  import("./create-loan/exceptions-and-enquiries/ViewDetails").then(
    (module) => ({
      default: module.ViewDetails
    })
  )
);
const LogoutPage = lazy(() =>
  import("./login/logout").then((module) => ({ default: module.LogoutPage }))
);
const NewsLetter = lazy(() =>
  import("./ui-components/NewsLetter").then((module) => ({
    default: module.NewsLetter
  }))
);
const MessagePopUp = lazy(() =>
  import("./ui-components/MessagePopUp").then((module) => ({
    default: module.MessagePopUp
  }))
);
const AssetManagement = lazy(() =>
  import("./assetManagement/AssetManagement").then((module) => ({
    default: module.AssetManagement
  }))
);

const SupportUI = lazy(() =>
  import("./create-loan/admin-screen/supportUI").then((module) => ({
    default: module.SupportUI
  }))
);

const BundleInfoView = lazy(() =>
  import(
    "./create-loan/purchase-and-settlements/bundleInfoView/BundleInfoView"
  ).then((module) => ({
    default: module.BundleInfoView
  }))
);
declare module "@mui/styles" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export function App() {
  //const history = createBrowserHistory();
  const store = rootStore();
  const ref2: any = useRef(null);

  useEffect(() => {
    // eslint-disable-next-line
    setCookie("overallPageHeight", ref2.current!?.clientHeight);
    removeCookie("globalSearchValue");
  }, []);

  const specialChar = ["<", ">", ";", "'"];

  useEffect(() => {
    document.onkeypress = function (e) {
      const event = e || window.event;
      return !specialChar.includes(event.key);
    };
  }, [specialChar]);

  useEffect(() => {
    const searchUrl = new URLSearchParams(window.location.href);
    const urlCode = searchUrl.get("code");
    if (urlCode) {
      setCookie("code", urlCode);
      setCookie("ad_code", urlCode, configParams.appraisalDashboardUrl);
    }
  }, []);

  const configParams = getConfig();
  if (configParams.environment === "production") {
    // eslint-disable-next-line
    console.log = () => {};
  }
  document.addEventListener("DOMContentLoaded", function () {
    function timeoutCallback() {
      IL.cleanup();
      sessionStorage.removeItem("excelUpload");
      sessionStorage.clear();
      localStorage.clear();
      if (window.location.hostname !== "localhost") {
        window.location.replace(`https://${window.location.hostname}/logout`);
      } else {
        window.location.replace(
          `http://${window.location.hostname}:3000/logout`
        );
      }
    }

    const settings: IInactivityConfig = {
      idleTimeoutTime: 28800000, // 8 hours
      timeoutCallback
    };

    const IL = new InactivityCountdownTimer(settings);
    IL.start();
  });

  // Guarded Route can be used for routes which is not allowed to be directly entered into the address bar.
  // The path in the redirectPath attribute is triggered when this path is tried to manually accessed
  // eslint-disable-next-line
  // const GuardedRoute = ({ component, redirectComponent, ...rest }: any) => {
  //   if (!getCookie("id_token") && rest.location.pathname !== "/logout") {
  //     setCookie("privateRoute", rest.location.pathname);
  //   }
  //   const otherParams = rest;
  //   otherParams.location.path = otherParams?.path;
  //   otherParams.location.redirectPath = otherParams?.redirectPath;
  //   const routeComponent = (props: any) => {
  //     if (getCookie("id_token")?.length) {
  //       if (props?.history?.action === "PUSH") {
  //         return React.createElement(component, props);
  //       }
  //       return (
  //         <Switch>
  //           <Redirect
  //             from={props.location.path}
  //             to={props.location.redirectPath}
  //           />
  //         </Switch>
  //       );
  //     }
  //     return <Redirect to={{ pathname: "/" }} />;
  //   };
  //   return <Route {...otherParams} render={routeComponent} />;
  // };

  // const PrivateRoute = ({ component, ...rest }: any) => {
  //   if (!getCookie("id_token") && rest.location.pathname !== "/logout") {
  //     setCookie("privateRoute", rest.location.pathname);
  //   }
  //   const routeComponent = (props: any) =>
  //     getCookie("id_token")?.length ? (
  //       React.createElement(component, props)
  //     ) : (
  //       <Redirect to={{ pathname: "/" }} />
  //     );
  //   return <Route {...rest} render={routeComponent} />;
  // };
  const generateClassName = createGenerateClassName({
    productionPrefix: "tc"
  });
  function Private({ component: Component, ...rest }: any) {
    if (
      !getCookie("id_token") &&
      rest.location &&
      rest.location.pathname !== "/logout"
    ) {
      setCookie("privateRoute", rest.location.pathname);
    }
    const auth = getCookie("id_token")?.length;
    return auth ? <Component {...(rest || {})} /> : <Navigate to="/" />;
  }

  return (
    <StylesProvider generateClassName={generateClassName} injectFirst>
      <CssBaseline>
        <GlobalCss />
        <div className="App" ref={ref2} id="DOMContentLoaded">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={MuiTheme}>
                <Provider store={store}>
                  <SuspenseWithFallBack>
                    <BrowserRouter>
                      <Interceptor />
                      <Loader />
                      <NewsLetter />
                      <HelpMenuWrapper />
                      <MessagePopUp />
                      <section
                        style={{ minHeight: "92vh", background: "#f8f9ff" }}
                      >
                        {/* <SuspenseWithFallBack> */}
                        <Routes>
                          <Route path="/documents" element={<Lists />} />
                          <Route path="/" element={<LoginLogoutCallback />} />
                          <Route
                            path="/logoutCallback"
                            element={<LoginLogoutCallback />}
                          />
                          <Route
                            path="/callBack"
                            element={<AuthCallbackPage />}
                          />
                          <Route
                            path="/components"
                            element={<Private component={UiComponents} />}
                          />
                          <Route
                            path="/loans/list"
                            element={<Private component={Home} />}
                          />
                          <Route
                            path="/asset/home"
                            element={<Private component={AssetManagement} />}
                          />
                          {/* <PrivateRoute
                          exact
                          path="/asset/home"
                          component={AssetManagement}
                        /> */}
                          {/* <PrivateRoute
                          exact
                          path="/asset/home/customerDetail/id/:id"
                          component={AssetManagement}
                        /> */}
                          <Route
                            path="/serviceronboarding/mappings"
                            element={
                              <Private component={ServicerOriginatorMapping} />
                            }
                          />
                          <Route
                            path="/drawDetails/provision"
                            element={<Private component={ProvisionView} />}
                          />
                          <Route
                            path="/drawDetails/addProvision/:loanId"
                            element={<Private component={AddProvisionView} />}
                          />
                          <Route
                            path="/drawDetails/:drawId"
                            element={<Private component={BulkDrawDetails} />}
                          />
                          <Route
                            path="/createDraw"
                            element={<Private component={CreateDraw} />}
                          />
                          <Route
                            path="/home"
                            element={<Private component={MultiAppHome} />}
                          />
                          <Route
                            path="/internal/loans/list"
                            element={<Private component={ILPHome} />}
                          />
                          {[
                            "/:loanType/:loanStage/:loanId/kdm",
                            "/internal/:loanType/:loanStage/:loanId/kdm"
                          ].map((path) => {
                            return (
                              <Route
                                key={path}
                                path={path}
                                element={
                                  <Private component={KeyDealMetricsDetails} />
                                }
                              />
                            );
                          })}
                          {[
                            "/loan/createLoan/:loanType/:loanStage/:loanId",
                            "/internal/loan/createLoan/:loanType/:loanStage/:loanId"
                          ].map((path) => {
                            return (
                              <Route
                                key={path}
                                path={path}
                                element={
                                  <Private component={CreateLoanRedirector} />
                                }
                              />
                            );
                          })}
                          <Route
                            path="/serviceronboarding/loan/:loanStage/:loanId/:onBoardingId/:dataReconcile"
                            element={<Private component={SOPurchasedLoan} />}
                          />
                          <Route
                            path="/drawDetails/:loanId/:drawId/documentTagging"
                            element={
                              <Private component={DrawDocumentReviewWrapper} />
                            }
                          />
                          <Route
                            path="/drawDetails/:loanId/:drawId/budgetReconciliation"
                            element={
                              <Private component={InspectionReviewWrapper} />
                            }
                          />
                          <Route
                            path="/drawDetails/:loanId/:drawId/budgetReconciliation/documentInNewTab/:tcDoc/:cacheKey/:name"
                            element={
                              <Private
                                component={OpenBudgetDocumentInNewTabRedirector}
                              />
                            }
                          />
                          <Route
                            path="/purchasedLoanDetails/loan/:loanType/:loanStage/:loanId/documentReview"
                            element={
                              <Private
                                component={PurchasedDocumentReviewProvider}
                              />
                            }
                          />
                          <Route
                            path="/purchasedLoanDetails/loan/:loanId/"
                            element={
                              <Private
                                component={PurchasedLoansDetailsProvider}
                              />
                            }
                          />
                          <Route
                            path="/serviceronboarding/loan/:loanType/:loanStage/:loanId/:onBoardingId/:dataReconcile/documentReview"
                            element={
                              <Private
                                component={OnboardingDocumentReviewWrapper}
                              />
                            }
                          />
                          <Route
                            path="/internal/loan/createLoan/:loanType/:loanStage/:loanId/budgetReview"
                            element={
                              <Private component={InternalBudgetReview} />
                            }
                          />
                          <Route
                            path="/internal/loan/createLoan/:loanType/:loanStage/:loanId/documentReview"
                            element={
                              <Private component={DocumentReviewRedirector} />
                            }
                          />
                          <Route
                            path="/internal/loan/createLoan/:loanType/:loanStage/:loanId/dataReconciliation"
                            element={
                              <Private
                                component={dataReconciliationRedirector}
                              />
                            }
                          />
                          <Route
                            path="/internal/loan/create/:loanType/:loanStage/:loanId/inquiry/:exceptionId/view"
                            element={<Private component={ViewDetailsInquiry} />}
                          />
                          <Route
                            path="/loan/create/:loanType/:loanStage/:loanId/:type/:exceptionId/:visibility/view"
                            element={<Private component={ViewDetails} />}
                          />
                          <Route
                            path="/internal/loan/createLoan/:loanType/:loanStage/:loanId/documentViewer/:cacheKey/:name"
                            element={
                              <Private
                                component={OpenDocumentInNewTabRedirector}
                              />
                            }
                          />
                          <Route
                            path="/loan/create/:loanType/:loanStage/:loanId/discussion/:chatId/view"
                            element={
                              <Private component={DiscussionDetailView} />
                            }
                          />
                          <Route
                            path="/loan/create/:loanType/:loanStage/:loanId/discussion/create"
                            element={
                              <Private component={CreateNewDiscussion} />
                            }
                          />
                          <Route
                            path="/loan/create/:loanType/:loanStage/:loanId/create/inquiry"
                            element={<Private component={CreateLoanEnquiry} />}
                          />
                          <Route
                            path="/loan/waiver/request/:loanStage/:loanId"
                            element={<Private component={WaiverRequest} />}
                          />
                          <Route
                            path="/loan/waiver/re-request/:loanStage/:loanId/:waiverId"
                            element={<Private component={WaiverReRequest} />}
                          />
                          <Route
                            path="/loan/waiver/request/:loanStage/:loanId/view/:waiverId"
                            element={<Private component={WaiverView} />}
                          />
                          <Route
                            path="/createLoan"
                            element={<Private component={CreateLoanType} />}
                          />
                          <Route
                            path="/create/scenario/:scenarioId"
                            element={<Private component={CreateNewScenario} />}
                          />
                          <Route
                            path="/admin/:loanId/:context"
                            element={<Private component={DeleteLoan} />}
                          />
                          <Route
                            path="/userCreation"
                            element={<Private component={UserCreation} />}
                          />
                          <Route
                            path="/support/:page/:partyId"
                            element={<Private component={SupportUI} />}
                          />

                          <Route
                            path="/support/:page/:partyId/:originator"
                            element={<Private component={SupportUI} />}
                          />

                          <Route
                            path="/loan/scenario/:scenarioId/view"
                            element={
                              <Private component={ScenarioViewDetails} />
                            }
                          />
                          <Route
                            path="/logout"
                            element={<Private component={LogoutPage} />}
                          />
                          <Route
                            path="/dashboard"
                            element={<Private component={Dashboard} />}
                          />
                          <Route
                            path="/notification-settings"
                            element={<Private component={Notification} />}
                          />
                          <Route
                            path="/bundle/:bundleId/loanType/:loanType/view"
                            element={<Private component={BundleInfoView} />}
                          />
                          <Route
                            path="/bundle/docusign/success"
                            element={<Private component={DocusignSuccess} />}
                          />
                        </Routes>
                        {/* </SuspenseWithFallBack> */}
                      </section>
                      <Footer />
                    </BrowserRouter>
                  </SuspenseWithFallBack>
                </Provider>
              </ThemeProvider>
            </StyledEngineProvider>
          </LocalizationProvider>
        </div>
      </CssBaseline>
    </StylesProvider>
  );
}
