import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
// import {
//   formatResponse,
//   getDocuments,
//   getLoanById,
//   getPropertyOriginalValuesByLoanId
// GET_LOAN_BY_ID_FAILED,
// GET_LOAN_BY_ID_SUCCESS,
// GET_PROPERTY_DETAILS,
// GET_PROPERTY_FAILED
// } from "../create-loan/create-loan.action";
import { useDispatch, useSelector } from "react-redux";
// import docFile from "../images/docFile.svg"
import { RootState } from "../stores/rootReducer";
import {
  DrawRolesEnum,
  URLParamsType,
  downloadDocsType
} from "../utils/constants";
import {
  createAndUpdateComments,
  getBudgetHistory,
  getBudgetReviewData,
  getCommentsList,
  updateBudgetReview,
  updateLineReduxData
} from "../stores/budgetReview/budgetReview.action";
import {
  formatLoanDetailsResponse,
  formatPropertyResponse
} from "../create-loan/CreateLoan";
import {
  updateLoanDetailsInRedux,
  updatePropertyDetailsInRedux
} from "../stores/loanAndPropertyDetails/loanAndPropertyDetails.action";
import {
  hideLoader,
  showLoader
} from "../stores/loaderAndException/loaderAndException.action";
// import { useStyles } from "../create-loan/documents/DocumentsForm.style";
import { taskNames } from "../stores/tasks/Tasks.action";
import {
  setBudgetDocsList,
  setBudgetGetResponse
} from "../stores/budgetReview/budgetReview.reducer";
// import {
//   getPropertyDetails,
//   setLoanByIdFailed,
//   setLoanByIdSuccess
// } from "../create-loan/create-loan.reducer";
// import { InspectionReview } from "./InspectionReview";
// @ts-ignore
import { InspectionBudget } from "@toorak/budget-reconcile-sdk";
import { BudgetReviewStore } from "../stores/budgetReview/budgetReview.interface";
import { usePolling } from "../custom-hooks/usePolling";
// import { ExtractionInProgressState } from "./BudgetReview.constant";
import { apiUrlHost } from "../stores/servicerOnboarding/servicerOnboarding.action";
import { isOrigUser, isRole } from "../utils/AccessManagement";
import {
  downloadDrawDocs,
  getBudgetReconcileDetails,
  getDrawDetailsByDrawId,
  getInspectionReviewDetails,
  saveReviewAndReconcileStatus,
  updateBudgetApprovedAmount,
  updateBudgetReconcileButtonAction,
  startReconcileAPI,
  updateInspectionReview,
  getBudgetReconcileReports,
  getReconcileItemHistory,
  getBudgetReconcileCommentsList,
  createAndUpdateIRComments,
  getIRandBRHistory,
  redoReconcileHandler,
  exportDiscussions,
  postDrawStageHistory,
  updateDrawDetails
} from "../stores/drawRequest/drawRequest.action";
import {
  DrawObj,
  DrawRequestStore
} from "../stores/drawRequest/drawRequest.interface";
import { getConfig } from "../config/config";
import { getPresignedURL } from "../network/apiService";
import { downloadMulipleDrawDocs } from "./DocumentsInNewTab";
import {
  EXCEL_FILE_TYPE,
  PDF_FILE_TYPE
} from "../create-loan/ttf-review/constants";
import { downloadFileForTTF } from "../stores/TapeToFile/TapeToFile.action";
import { getChatsList } from "../stores/commentsSection/comment.action";
import { getCookie } from "../utils/cookies";
import Cookies from "js-cookie";
import { DrawStage } from "@toorak/tc-common-fe-sdk";

const ExtractionInProgressState = [
  "PREDICTION_PENDING",
  "PREDICTION_IN_PROGRESS"
];
export const InspectionReviewWrapper = () => {
  const [drawDocsDownload, setDrawDocsDownload] = useState<any[]>([]);
  const [showNoDocs, setShowNoDocs] = useState<boolean>(false);
  const [isManualEntry, setIsManualEntry] = useState<boolean>(false);
  // const navigate = useNavigate();
  // const dpcsClasses = useStyles();
  const dispatch = useDispatch();
  const { loanId, loanStage, loanType } = useParams<URLParamsType>() as any;
  const [commentsList, setCommentsList] = useState<any[]>([]);
  const firstName = Cookies.get("firstName");
  const lastName = Cookies.get("lastName");
  // useEffect(() => {
  //   if (loanType && loanStage && loanId && loanType !== "BridgeLoan") {
  //     navigate(`/internal/loan/createLoan/${loanType}/${loanStage}/${loanId}`);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [loanType, loanStage, loanId]);
  const {
    selectedBudgetDoc,
    isLineItemsEditDisable,
    budgetApprovalHistory,
    budgetStatusHistory,
    budgetReviewHeader,
    // lineItemsList,
    budgetButtonInfo,
    unitVal,
    totalBudget,
    BulkBudgetSum,
    totalfinancedBudget
  } = useSelector<RootState, BudgetReviewStore>(
    (state: { budgetReviewStore: BudgetReviewStore }) => state.budgetReviewStore
  );
  const { startPolling, stopPolling } = usePolling();
  const isOrig = isOrigUser();
  const { drawId } = useParams<any>();
  let {
    drawDetails,
    drawDocs,
    drawTaggedDocs,
    loanIds,
    inspectionReviewData,
    propType,
    budgetReconcileData,
    budgetReconcileReports,
    reconcileItemHistory,
    selectedBankDetails
  } = useSelector<RootState, DrawRequestStore>(
    (state) => state.drawRequestStore
  );

  const drawDiscussionsHistory: any[] = useSelector<RootState, any>(
    (state) => state.drawRequestStore.drawDiscussions
  );
  useEffect(() => {
    if (!drawId) return;
    getCommentsDataAndBudgetData(drawId);
    // eslint-disable-next-line
  }, [drawId]);

  const getBothHistory = async () => {
    if (!drawId) return;
    let reqBody = {
      resourceIds: [drawId],
      resourceTypes: ["DRAW"],
      taskNames: [
        taskNames.TAG,
        taskNames.BUDGET_RECONCILE,
        taskNames.INSPECTION_REVIEW
      ]
    };
    try {
      const history = await getIRandBRHistory(reqBody);
      return Promise.resolve(history);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  useEffect(() => {
    if (!drawId) return;
    dispatch(getDrawDetailsByDrawId(drawId, isOrig, true));
    // dispatch(getDocsListbyDrawId(isOrig, drawId));
  }, [drawId, dispatch, isOrig]);

  useEffect(() => {
    if (!loanId) return;
    if (!drawId) return;
    // dispatch(getDocsListbyDrawId(isOrig, loanId));
    getInspectionReviewDetails(loanId, drawId, dispatch);
  }, [drawId, loanId, isOrig]);

  const getDrawDocsCB = async (docIds: any) => {
    try {
      // const docIds = drawTaggedDocs?.data[0].id;
      if (!docIds) return;
      if (!drawId) return;
      const response: any = await downloadDrawDocs(
        [docIds],
        drawId,
        isOrig,
        drawDetails.originatorId
      );
      setDrawDocsDownload(response);
      return Promise.resolve(response);
    } catch (e) {
      dispatch(hideLoader());
      // console.log("error while multiple download", e);
      return Promise.reject();
    }
  };

  async function updateInspectionLineItems(
    updatedData: any[],
    budgetTotalInfo?: any
  ) {
    // let lineItems = fieldsData;

    // if (updatedData) {
    //   lineItems = updatedData;
    // }
    try {
      if (!drawId) return;
      let reqBody = { ...inspectionReviewData };
      reqBody.lineItems = [...updatedData];
      const response = updateInspectionReview(drawId, reqBody, dispatch);
      return Promise.resolve(response);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async function getCommentsDataAndBudgetData(drawId: string) {
    dispatch(showLoader());
    let resp = await getBudgetReconcileCommentsList(`${drawId}`);
    setCommentsList(resp);
    dispatch(hideLoader());
    // dispatch(
    //   getBudgetReviewData(
    //     docsId,
    //     resp,
    //     budgetButtonInfo.financedBudgetPercentage,
    //     loanDetails.propertyDetails
    //   )
    // );
  }

  // const onPollSuccess = (response: any) => {
  //   if (
  //     ExtractionInProgressState.includes(budgetReviewResponse?.processState) &&
  //     isManualEntry
  //   ) {
  //     stopPolling();
  //   }
  //   if (ExtractionInProgressState?.includes(response?.processState)) {
  //     return true; // Continue polling
  //   }
  //   //when status moved to prediction completed
  //   stopPolling();
  //   updateLineReduxData(
  //     response,
  //     dispatch,
  //     budgetButtonInfo.financedBudgetPercentage,
  //     commentsList,
  //     true
  //   );
  //   dispatch(setBudgetGetResponse(response));
  // };

  // const onPollTimeExceed = () => {
  //   console.error("time exceed");
  // };

  // useEffect(() => {
  //   if (!drawDetails.toorakLoanId) return;
  //   if (
  //     ExtractionInProgressState?.includes(budgetReviewResponse?.processState)
  //   ) {
  //     const url = `${apiUrlHost}/ocr/extraction/${drawDetails.toorakLoanId}/documents`;
  //     startPolling({
  //       url,
  //       interval: 5000,
  //       onSuccess: (res: any) => {
  //         return onPollSuccess(res.data);
  //       },
  //       method: "GET",
  //       onExceedingPollingWaitTime: () => {
  //         onPollTimeExceed();
  //       },
  //       pollingWaitTime: 300000 // allow 5 minutes polling
  //     });
  //   }

  //   // eslint-disable-next-line
  // }, [ExtractionInProgressState, budgetReviewResponse?.processState]);

  // const { loanDetails } = useSelector<RootState, any>(
  //   (state) => state.createLoanStore
  // );

  // const { loanState } = loanDetails;

  // useEffect(() => {
  //   // loanState && getDocsList();
  //   if (!loanState || loanState === "Data Entry") return;

  //   (async () => {
  //     dispatch(showLoader());
  //     try {
  //       const [prePurchaseList, postCloseList] = (await Promise.all([
  //         getDocuments(
  //           loanId,
  //           `${loanStage}-prePurchase`,
  //           `${loanStage}-prePurchase`,
  //           loanState,
  //           true
  //         ) as any,
  //         getDocuments(
  //           loanId,
  //           `${loanStage}-postClose`,
  //           `${loanStage}-postClose`,
  //           loanState
  //         )
  //       ])) as any;

  //       const budgetDocs = filterObjectsByTag([
  //         ...postCloseList.response.data,
  //         ...prePurchaseList.response.data
  //       ]);
  //       if (budgetDocs.length) {
  //         const tempBudgetFilesList = budgetDocs.map(
  //           (fileData: { name: string }) => {
  //             const fileType = fileData.name.split(".").pop()?.toLowerCase();
  //             return {
  //               ...fileData,
  //               fileType,
  //               ...{
  //                 title: fileData.name
  //               }
  //             };
  //           }
  //         );
  //         setbudgetDocs(tempBudgetFilesList);
  //         if (tempBudgetFilesList.length) {
  //           setShowNoDocs(true);
  //         }

  //         dispatch(setBudgetDocsList(tempBudgetFilesList));
  //       }
  //       if (!budgetDocs.length) {
  //         setShowNoDocs(true);
  //       }
  //       dispatch(hideLoader());
  //     } catch (e) {
  //       console.error(e);
  //       dispatch(hideLoader());
  //     }
  //   })();
  //   // eslint-disable-next-line
  // }, [loanId, loanState, loanStage]);
  // function filterObjectsByTag(docsList: any[]) {
  //   const filteredObjects: any[] = [];
  //   docsList.forEach((doc) => {
  //     if (doc.isPurchasePrimary) {
  //       const tags = doc.TagsInternal || [];
  //       for (let j = 0; j < tags.length; j++) {
  //         const tagCode = tags[j].tag.code;
  //         if (tagCode === "REBD" || tagCode === "SCOW") {
  //           filteredObjects.push(doc);
  //           break;
  //         }
  //       }
  //     }
  //   });
  //   return filteredObjects;
  // }

  const role = isRole(DrawRolesEnum.DRAW_MANAGER)
    ? DrawRolesEnum.DRAW_MANAGER
    : DrawRolesEnum.DRAW_MEMBER;

  const downloadFromServer = async (
    cacheKey: any,
    setDownloadProgress: any,
    setCurrentRequest: any
  ) => {
    const ourRequest = axios.CancelToken.source();
    setCurrentRequest(ourRequest);
    let downloadURL = "";
    if (cacheKey) {
      const responseData: any = await getDrawDocsCB(Number(cacheKey));
      // >>>>>>>>>>>>>>>>>>>>>
      downloadURL = responseData?.data?.downloadLinks[0]?.url;
    }
    if (!downloadURL) return null;
    let response;
    response = await axios({
      url: downloadURL,
      cancelToken: ourRequest.token,
      responseType: "blob",
      onDownloadProgress(progressEvent) {
        if (progressEvent?.total) {
          const progress = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          );
          setDownloadProgress(progress);
        }
      }
    });
    const { data } = response;

    return data;
  };
  const onClickOpenInNewTab = (cacheKey: string, name: string) => {
    window.open(
      `${
        getConfig().redirectUrl
      }internal/loan/createLoan/${loanType}/${loanStage}/${loanId}/documentViewer/${cacheKey}/${name}`
    );
  };

  const fetchPresignedURL = async (id: string) => {
    // const responseData: any = await getPresignedURL("LOAN", loanId, );
    const responseData: any =
      drawId &&
      (await downloadDrawDocs([id], drawId, isOrig, drawDetails.originatorId));
    const url = responseData?.data?.downloadLinks[0]?.url;
    if (!url) return null;
    // setPresignedURL(url);
    return url;
  };

  const budgetReconcileButtonAction = async (payload: any) => {
    try {
      if (drawId) {
        await updateBudgetReconcileButtonAction(drawId, payload, dispatch);
      }
    } catch (err) {
      console.log("error : budgetReconcileButtonAction", err);
    }
  };

  const saveBudgetApprovedAmount = async (payload: any, status: any) => {
    try {
      if (drawId) {
        const taskPayload = payload[0]?.taskPayload;
        delete payload[0].taskPayload;
        await updateBudgetApprovedAmount(drawId, payload, dispatch);
        const res = await getBudgetReconcileData(false);
        if (status === "Pending") {
          await updateReviewAndReconcileStatus(taskPayload, undefined);
          // await getBothHistory();
        }
        return res;
      }
    } catch (err) {
      console.log("error : saveBudgetApprovedAmount", err);
    }
  };

  const getBudgetReconcileData = async (showLoader: any) => {
    try {
      if (drawId) {
        const res = await getBudgetReconcileDetails(drawId, dispatch, false);
        return res;
      }
    } catch (err) {
      console.log("error : getBudgetReconcileData", err);
      return [];
    }
  };

  const getBudgetReconcileReportsDocs = async () => {
    try {
      if (drawId) {
        const res = await getBudgetReconcileReports(
          drawId,
          drawDetails.toorakLoanId,
          dispatch
        );
        return {...res,drawId};
      }
    } catch (err) {
      console.log("error : getBudgetReconcileReportsDocs", err);
    }
    return {};
  };

  const updateReviewAndReconcileStatus = async (
    payload: any,
    operation: any
  ) => {
    try {
      if (drawId) {
        await saveReviewAndReconcileStatus(
          drawId,
          payload,
          dispatch,
          operation
        );
      }
    } catch (err) {
      console.log("error : getBudgetReconcileData", err);
      return err;
    }
  };

  const handleDiscussionsSave = (discussionChat: any) => {
    let tempDiscussionHistory = JSON.parse(
      JSON.stringify(drawDiscussionsHistory)
    );
    let discObj = {
      userName: firstName + " " + lastName,
      text: JSON.stringify(discussionChat),
      role
    };
    dispatch(
      exportDiscussions(
        discObj,
        drawId,
        false,
        tempDiscussionHistory,
        drawDetails.originatorId
      )
    );
  };

  const handledApproveMismatch = async (
    chat: any,
    amount: any,
    discussionObj: any
  ) => {
    try {
      if (!drawId) return;
      handleDiscussionsSave(discussionObj);
      await dispatch(
        postDrawStageHistory(
          drawId,
          "Originator Pending",
          role,
          drawDetails?.originatorId,
          chat
        )
      );
      const drawObj: DrawObj = JSON.parse(JSON.stringify(drawDetails));
      drawObj.drawAmountDetails.drawAmount = amount;
      drawObj.stage = DrawStage.originatorPending;
      // const drawObjPayload = {
      //   drawAmountDetails: drawObj.drawAmountDetails,
      //   stage: DrawStage.originatorPending,
      //   primaryLoanId: drawObj.primaryLoanId
      // };
      await dispatch(
        updateDrawDetails(
          drawObj,
          selectedBankDetails,
          drawId,
          isOrig,
          drawObj.stage,
          drawObj.originatorId
        )
      );
    } catch (err) {
      console.log(`error : handledApproveMismatch: ${err}`);
    }
  };

  const redoReconcile = async () => {
    try {
      if (drawId) {
        await redoReconcileHandler(drawId, dispatch);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const startReconcile = async () => {
    try {
      if (!drawId) return;
      await startReconcileAPI(drawId);
      return true;
    } catch (e) {
      return false;
    }
  };

  const handleReportDocs = async (docId: any, name: any, isDraw: any) => {
    try {
      if (!drawId) return;
      const fileType = name.split(".").pop();
      const tcDoc = !isDraw ? "TC" : "DRAW";
      console.log("isss doc", isDraw);
      if (EXCEL_FILE_TYPE.includes(fileType)) {
        if (isDraw) {
          await downloadMulipleDrawDocs(
            drawId,
            docId,
            downloadDocsType.download,
            name,
            dispatch
          );
        } else {
          await downloadFileForTTF(
            name,
            docId,
            drawDetails.toorakLoanId,
            "POST-postClose",
            dispatch
          );
        }
      } else if (PDF_FILE_TYPE.includes(fileType)) {
        let newWindow: any = window.open(
          `${getConfig().redirectUrl}drawDetails/${
            drawDetails.toorakLoanId
          }/${drawId}/budgetReconciliation/documentInNewTab/${tcDoc}/${docId}/${name}`
        );
        var cookieName = "orig_id";
        var cookieValue = getCookie("org_id");
        newWindow.document.cookie = cookieName + "=" + cookieValue + "; path=/";
      }
    } catch (err) {}
  };

  const handleAddCommentProceed = async (row: any, comment: string) => {
    if (!drawId) return;
    try {
      let chatId = await createAndUpdateIRComments(
        row.commentDiscussionId,
        drawId,
        comment,
        row.budgetLineItemsId
      );
      return Promise.resolve(chatId);
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  };

  const getCommentsData = async (chatId: any) => {
    console.log(chatId);
    try {
      let data = await getChatsList(chatId);
      return Promise.resolve(data);
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  };

  const getLineItemHistory = async (itemId: any) => {
    try {
      await getReconcileItemHistory(itemId, dispatch);
    } catch (err) {}
  };

  const getInspectionData = () => {
    if (!loanId) return;
    if (!drawId) return;
    // dispatch(getDocsListbyDrawId(isOrig, loanId));
   getInspectionReviewDetails(loanId, drawId, dispatch);
  };
  
  return drawDetails ? (
    <InspectionBudget
      inspectionDocs={drawTaggedDocs?.data || []}
      showNoDocs={showNoDocs}
      drawDetails={drawDetails}
      publicURL={process.env.PUBLIC_URL}
      inspectionReviewDetails={{
        docsViewer: {
          downloadFromServerCB: downloadFromServer,
          onClickOpenInNewTabCB: onClickOpenInNewTab,
          fetchPresignedURLCB: fetchPresignedURL
        },
        chatDetails: {
          getCommentsDataCB: getCommentsData,
          createAndUpdateCommentsCB: handleAddCommentProceed
        },
        statusDetails: {
          getInspectionHistoryCB: getBothHistory,
          updateReviewAndReconcileStatusCB: updateReviewAndReconcileStatus
        },
        startReconcileCB: startReconcile,
        selectedBudgetDoc,
        isLineItemsEditDisable,
        updateInspectionReviewCallback: updateInspectionLineItems,
        budgetApprovalHistory,
        BulkBudgetSum: { grandRehabBudget: null },
        budgetStatusHistory,
        budgetReviewHeader,
        budgetButtonInfo,
        unitVal,
        totalBudget,
        totalfinancedBudget,
        commentsList,
        lineItemsList: inspectionReviewData?.lineItems,
        getInspectionData: getInspectionData,
        propType: propType,
        usePolling: {
          startPolling,
          pollingURL: `${apiUrlHost}/ocr/extraction/${drawDetails.toorakLoanId}/documents`,
          stopPolling,
          isManualEntry,
          setIsManualEntry
        }
      }}
      budgetReconcileButtonAction={budgetReconcileButtonAction}
      saveBudgetApprovedAmount={saveBudgetApprovedAmount}
      getBudgetItemCB={getBudgetReconcileData}
      budgetReconcileData={budgetReconcileData}
      getBudgetReconcileReportsDocs={getBudgetReconcileReportsDocs}
      budgetReconcileReports={budgetReconcileReports}
      updateReviewAndReconcileStatus={updateReviewAndReconcileStatus}
      handleReportDocs={handleReportDocs}
      getLineItemHistory={getLineItemHistory}
      reconcileItemHistory={reconcileItemHistory}
      redoReconcile={redoReconcile}
      handledApproveMismatch={handledApproveMismatch}
    />
  ) : (
    <>{"loading..."}</>
  );
};
