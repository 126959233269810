/* eslint-disable no-shadow */
import { waiverStatusLowerCase } from "@toorak/tc-common-fe-sdk";
import { isLATUser } from "../../utils/AccessManagement";
import { formatValueByType, isEmptyValue } from "../../utils/formatChecks";
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable no-empty */

import { RepresentableEvaluationResults } from "./EvaluationResults";
import { guidlineTestsMap } from "./GuidlineTestConstants";

/** second parameter request is used in eval(). dont remove it. */
/* eslint-disable no-eval */
export function evaluation(templateObject: string, request?: any) {
  let firstEvaluation;
  try {
    firstEvaluation = eval(templateObject);
    if (firstEvaluation.includes("undefined"))
      return "Few of the inputs for the test are not passing the Toorak loan evaluation criteria";
    try {
      let secondEval = eval(firstEvaluation);
      secondEval = secondEval.replace(/"undefined"/g, "");
      secondEval = secondEval.replace(/undefined/g, "");
      return secondEval;
    } catch (error) {
      if (firstEvaluation.includes("undefined"))
        return "Few of the inputs for the test are not passing the Toorak loan evaluation criteria";
      firstEvaluation = firstEvaluation.replace(/"undefined"/g, "");
      firstEvaluation = firstEvaluation.replace(/undefined/g, "");
      return firstEvaluation;
    }
  } catch (error) {
    return "Few of the inputs for the test are not passing the Toorak loan evaluation criteria";
  }
}

export function evalTestNames(representableTestName: string) {
  try {
    return eval(representableTestName);
  } catch (error) {
    console.log(error);
    return null;
  }
}
export function getTemplateRequestBody(loanEvaluationResult: {
  loanResult: any;
  propertiesResults: any;
}) {
  let testName: any[] = [];
  if (loanEvaluationResult) {
    if (
      loanEvaluationResult.loanResult &&
      Object.values(loanEvaluationResult.loanResult).length > 0 &&
      Object.values(loanEvaluationResult.loanResult)[0]
    ) {
      const tempArray = testName;
      testName = [
        ...tempArray,
        ...getFailedTestName(
          Object.values(loanEvaluationResult.loanResult)[0],
          "loanResult"
        )
      ];
      getRequestBodyForRunningEval(
        Object.values(loanEvaluationResult.loanResult)[0]
      );
    }
    if (loanEvaluationResult.propertiesResults) {
      Object.keys(loanEvaluationResult.propertiesResults).map((key) => {
        const tempArray = testName;
        testName = [
          ...tempArray,
          ...getFailedTestName(
            loanEvaluationResult.propertiesResults[key],
            "propertiesResults"
          )
        ];
      });
    }
  }
  return testName;
}
export function getRequestBodyForRunningEval(loanResult: any) {
  let loanResultInputForEval = {
    stabilizedFlag:
      loanResult.loanCharacterisations?.stabilizedFlag ||
      loanResult.loanCharacterisation?.stabilizedFlag,
    customerType:
      loanResult.loanCharacterisations?.customerType ||
      loanResult.loanCharacterisation?.customerType,
    purchaseRefinance:
      loanResult.loanCharacterisations?.purchaseOrRefinance ||
      loanResult.loanCharacterisation?.purchaseOrRefinance,
    dscr: loanResult.loanEconomics?.dscr,
    maxAsIsLTV: loanResult.maxLeverage?.maxAsIsLTV,
    maxLTC: loanResult.maxLeverage?.maxLTC,
    afterAdjustmentMaxLTC:
      loanResult.toorakProceeds?.afterAdjustmentMaxLTC ||
      loanResult.toorakProceeds?.toorakAdjustedAsIsLtc,
    afterAdjustmentMaxLTVAsIs:
      loanResult.toorakProceeds?.afterAdjustmentMaxLTVAsIs ||
      loanResult.toorakProceeds?.toorakAdjustedAsIsLtv,
    originalAsIsLtv: loanResult.loanFeatures?.originalAsIsLtv,
    originalLtc: loanResult.loanFeatures?.originalLtc,
    toorakLoanType: loanResult?.loanCharacterization?.toorakLoanType
  };
  if (loanResult.borrowerInfo) {
    loanResultInputForEval = {
      ...loanResultInputForEval,
      ...loanResult.borrowerInfo,
      ...loanResult
    };
  }
  return loanResultInputForEval;
}
export function getFailedTestName(evaluationResults: any, testType: string) {
  const failedTestNames: any[] = [];
  const validKeys =
    evaluationResults &&
    Object.keys(evaluationResults).filter(
      (key) =>
        typeof evaluationResults[key] === "object" && evaluationResults[key]
    );
  if (validKeys) {
    validKeys.forEach((key: any) => {
      const object = evaluationResults[key];
      Object.entries(object).map((entry) => {
        if (key === "warehouseLine") {
          failedTestNames.push(
            `loan.${testType}.${key}.${entry[0]}.message.FAIL`
          );
        }
        if (
          typeof entry[1] === "string" &&
          (entry[1].toLowerCase() === "fail" ||
            entry[1].toLowerCase() === "waived") &&
          entry[0].toLowerCase() !== "sectioneligibility" &&
          entry[0].toLowerCase() !== "propertyleveltest"
        ) {
          failedTestNames.push(`${testType}.${key}.${entry[0]}.message.FAIL`);
        }
        failedTestNames.push(`testNames.${entry[0]}`);
      });
      failedTestNames.push(`testNames.${key}`);
    });
  }
  return failedTestNames;
}
export function passedAndFailedTests(evaluationResults: any): {
  passed: string[];
  failed: string[];
} {
  const passedTest: string[] = [];
  const failedTest: string[] = [];

  const validKeys =
    evaluationResults &&
    Object.keys(evaluationResults).filter(
      (key) =>
        typeof evaluationResults[key] === "object" && evaluationResults[key]
    );
  if (validKeys) {
    validKeys.forEach((key: any) => {
      const tests = [
        "multiFamilyMixedUse",
        "loanEconomics",
        "logicTest",
        "guideLineLimit",
        "bridgeRentalPropertyTest",
        "maxLeverageTest",
        "guidelineTest",
        "logicTests",
        "maxMinParameters"
      ];
      if (
        tests.includes(key) ||
        (isLATUser() && (key === "masterTest" || key === "finalCalculation"))
      ) {
        const object = evaluationResults[key];
        const validInnerKeys: [string, unknown][] = Object.entries(
          object
        ).filter(
          (entry) =>
            typeof entry[1] === "string" &&
            (entry[1].toLowerCase() === "pass" ||
              entry[1].toLowerCase() === "fail" ||
              entry[1].toLowerCase() === "waived") &&
            entry[0].toLowerCase() !== "sectioneligibility" &&
            entry[0].toLowerCase() !== "propertyleveltest"
        );
        if (validInnerKeys.length > 0) {
          validInnerKeys
            .filter(
              (item) =>
                (item[1] as string).toLowerCase() === "fail" ||
                (item[1] as string).toLowerCase() === "pass" ||
                (item[1] as string).toLowerCase() === "waived"
            )
            .map((item) => {
              if (
                (item[1] as string).toLowerCase() === "pass" ||
                (item[1] as string).toLowerCase() === "waived"
              ) {
                passedTest.push(item[0]);
              } else if ((item[1] as string).toLowerCase() === "fail") {
                failedTest.push(item[0]);
              }
            });
        }
      }
    });
  }
  return { passed: passedTest, failed: failedTest };
}

/** this function is used to add property id to each child object of property result.
 * adding property id to each child is useful in displaying failure for reason.
 */
export const alterPropertyEvaluationResult = (
  evalObject: any,
  propertyId: any,
  propertyAddress?: any
) => {
  const evaluationResults = evalObject;
  let tempObj = {};
  const validKeys =
    evaluationResults &&
    Object.keys(evaluationResults).filter(
      (key) =>
        typeof evaluationResults[key] === "object" && evaluationResults[key]
    );
  if (validKeys) {
    validKeys.forEach((key: any) => {
      const objectToAdd = {
        propertyId,
        propertyAddress
      };
      tempObj = {
        ...tempObj,
        ...{ [key]: { ...evaluationResults[key], ...objectToAdd } }
      };
    });
  }
  return tempObj;
};
export const getWaiverBasedOnKey = (waiverKey: string, waiverObj: any) => {
  if (waiverObj?.length > 0) {
    return waiverObj.find(
      (item: any) => item?.waiverKeys && item.waiverKeys.includes(waiverKey)
    );
  }
  return null;
};

export const alterEvalRequestBody = (
  evalResults: any,
  tempResponse: any,
  loanInputs: any,
  propertyInputs: any,
  evalTemplateRules: any,
  loanId: string,
  waivers: any,
  showIntermediateValues: boolean | undefined,
  loanStage: any
): RepresentableEvaluationResults[] => {
  const originalLoanAmount =
    loanInputs?.fesLoanEconomics?.originalLoanAmount ||
    loanInputs?.bridgeLoanEconomics?.payload?.originalLoanAmount ||
    loanInputs?.thirtyYearLoanEconomics?.payload?.loanAmount;
  let requestObj = {
    loan: {
      loanStage,
      loanStructure:
        loanInputs?.bridgeLoanInformation?.payload?.selectedLoanStructure ||
        loanInputs?.fesLoanInformation?.loanStructure,
      loanPurpose:
        loanInputs?.bridgeLoanInformation?.payload?.loanPurpose ||
        loanInputs?.thirtyYearLoanInformation?.payload?.loanPurpose ||
        loanInputs?.fesLoanInformation?.loanPurpose,
      totalBudgetAmount:
        loanInputs?.bridgeLoanEconomics?.payload?.budgetAmount ||
        loanInputs?.fesLoanEconomics?.rehabBudget,
      originalLoanAmount: originalLoanAmount
        ? originalLoanAmount.replace("$", "").replace(/,/g, "")
        : originalLoanAmount,
      crossCollaterlization:
        loanInputs?.thirtyYearLoanInformation?.payload?.selectedCross ||
        loanInputs?.bridgeLoanInformation?.payload?.selectedCross,
      numberOfProperties:
        loanInputs?.fesLoanInformation?.numberOfProperties ||
        loanInputs?.bridgeLoanInformation?.payload?.numberOfProperties ||
        loanInputs?.thirtyYearLoanInformation?.payload?.numberOfProperties,
      cutOffDateMaximumLoanAmount:
        loanInputs?.bridgeLoanEconomics?.payload?.cutOffMaxLoanAmount,
      totalOriginationAndDiscountPoints:
        loanInputs?.fesLoanEconomics?.totalOriginationAndDiscountPoints
    },
    property: {
      // in loan level it will be empty
      propertyType: "",
      numberOfUnits: "",
      originalAsIsAppraisalValue: "",
      originalAsIsRepairedValue: "",
      purchasePrice: ""
    },
    borrower: {},
    properties: {},
    loanResult: evalTemplateRules.inputToRunEval?.loanResult || {},
    propertyResult: evalTemplateRules.inputToRunEval?.propertyResult || {}
  };
  let result: any[] = [];
  const propertyOrLoanResult =
    evalResults &&
    Object.keys(evalResults).filter(
      (key) => typeof evalResults[key] === "object" && evalResults[key]
    );
  propertyOrLoanResult &&
    propertyOrLoanResult.forEach((loanOrPropertyResults: any) => {
      const evaluationResults = evalResults[loanOrPropertyResults];
      const outerMostLayer =
        evaluationResults &&
        Object.keys(evaluationResults).filter(
          (key) =>
            typeof evaluationResults[key] === "object" && evaluationResults[key]
        );
      outerMostLayer.forEach((field: any) => {
        const firstLayer =
          evaluationResults &&
          Object.keys(evaluationResults[field]).filter(
            (key) =>
              typeof evaluationResults[field][key] === "object" &&
              evaluationResults[field][key]
          );
        firstLayer.forEach((testGroup: any) => {
          /** Intermediate values need to be shown for these fields in rule response. */
          const tests = [
            "multiFamilyMixedUse",
            "loanEconomics",
            "logicTest",
            "guideLineLimit",
            "bridgeRentalPropertyTest",
            "maxLeverageTest",
            "guidelineTest",
            "logicTests",
            "maxMinParameters"
          ];
          if (
            tests.includes(testGroup) ||
            (isLATUser() &&
              (testGroup === "masterTest" || testGroup === "finalCalculation"))
          ) {
            const representableTestGroupName =
              tempResponse[`testNames.${testGroup}`];
            const object = evaluationResults[field][testGroup];
            const validInnerKeys: [string, unknown][] = Object.entries(
              object
            ).filter(
              (entry) =>
                typeof entry[1] === "string" &&
                (entry[1].toLowerCase() === "pass" ||
                  entry[1].toLowerCase() === "fail" ||
                  entry[1].toLowerCase() === "waived") &&
                entry[0].toLowerCase() !== "sectioneligibility" &&
                entry[0].toLowerCase() !== "propertyleveltest"
            );
            let isTestPassed =
              object.sectionEligibility &&
              object.sectionEligibility.toLowerCase() === "pass";
            const { propertyId, propertyAddress } = object;
            if (isTestPassed || validInnerKeys.length > 0) {
              isTestPassed =
                object.sectionEligibility &&
                validInnerKeys.every(
                  (item) =>
                    object.sectionEligibility.toLowerCase() !== "fail" &&
                    !(
                      (item[1] as string).toLowerCase() === "fail" ||
                      (item[1] as string).toLowerCase() === "waived"
                    )
                );
              if (isTestPassed) {
                const obj = result.find(
                  (item) => item.testGroupName === testGroup
                );
                // if this test group is not there then add it to array..else edit.
                if (obj) {
                  if (obj.overAllResults === "FAIL") {
                    console.debug("in future add passed test here");
                  }
                } else {
                  result.push({
                    overAllResults: "PASS",
                    failingTests: [],
                    representableTestGroupName:
                      eval(representableTestGroupName) || testGroup,
                    testGroupName: testGroup
                  });
                }
              } else {
                const failingTests = validInnerKeys
                  .filter(
                    (item) =>
                      (item[1] as string).toLowerCase() === "fail" ||
                      (item[1] as string).toLowerCase() === "waived"
                  )
                  .map((item) => {
                    const templateMessage =
                      tempResponse[
                        `${loanOrPropertyResults}.${testGroup}.${item[0]}.message.FAIL`
                      ];

                    const representableTestName =
                      tempResponse[`testNames.${item[0]}`];
                    let waiverKey = `${loanOrPropertyResults}.${propertyId}.${testGroup}.${item[0]}`;
                    if (loanOrPropertyResults === "loanResult") {
                      waiverKey = `${loanOrPropertyResults}.${loanId}.${testGroup}.${item[0]}`;
                    }
                    const property = propertyInputs?.find(
                      (x: any) => x.propertyId === Number(propertyId)
                    );
                    const propertyObj = {
                      property: {
                        propertyType:
                          property?.propertyInformation?.payload.propertyType ||
                          property?.fesPropertyInformation?.payload?.propertyType,
                        numberOfUnits:
                          property?.propertyInformation?.payload
                            .numberOfUnits ||
                          property?.fesPropertyInformation?.payload?.numberOfUnits,
                        originalAsIsAppraisalValue:
                          property?.propertyEconomics?.payload
                            .originalAppraisalValue ||
                          property?.fesPropertyEconomics?.payload
                            ?.originalAppraisalValue,
                        originalAsIsRepairedValue:
                          property?.propertyEconomics?.payload
                            .originalRepairedAppraisalValue ||
                          property?.fesPropertyEconomics?.payload
                            ?.originalAsRepairedAppraisedValue,
                        numberOfProperties:
                          loanInputs?.fesLoanInformation?.numberOfProperties ||
                          loanInputs?.bridgeLoanInformation?.payload
                            ?.numberOfProperties ||
                          loanInputs?.thirtyYearLoanInformation?.payload
                            ?.numberOfProperties,
                        purchasePrice:
                          property?.propertyEconomics?.payload.purchasePrice ||
                          property?.fesPropertyEconomics?.payload?.purchasePrice
                      }
                    };
                    requestObj = { ...requestObj, ...propertyObj };
                    const failureMessage = templateMessage
                      ? evaluation(templateMessage, requestObj)
                      : "Few of the inputs for the test are not passing the Toorak loan evaluation criteria";
                    const waiverData = getWaiverBasedOnKey(waiverKey, waivers);
                    const returnObj = {
                      testName: item[0],
                      testGroup,
                      overAllResults: "FAIL",
                      representableTestName:
                        evalTestNames(representableTestName) || item[0],
                      reasonForFailure: failureMessage,
                      redirectionButtonName: propertyId
                        ? propertyAddress?.street_line || "Property Tab"
                        : "Loan Details",
                      propertyId,
                      waiverData
                    };
                    if (waiverData?.waiverResponses?.length > 0) {
                      const waiverResponseObject = waiverData.waiverResponses
                        .slice()
                        .sort(
                          (a: any, b: any) =>
                            Date.parse(a.responseDate) -
                            Date.parse(b.responseDate)
                        );
                      if (
                        waiverResponseObject.length &&
                        waiverResponseObject[waiverResponseObject.length - 1]
                          .isApproved
                      ) {
                        returnObj.overAllResults = "PASS";
                      }
                    } else {
                      returnObj.overAllResults = "FAIL";
                    }
                    return returnObj;
                  });
                const index = result.findIndex(
                  (item) => item.testGroupName === testGroup
                );
                if (index >= 0) {
                  const tempObj = result[index];
                  tempObj.failingTests = [
                    ...tempObj.failingTests,
                    ...failingTests
                  ];
                  tempObj.overAllResults = "FAIL";
                  tempObj.overallFailingTests = getOverAllResults(
                    tempObj.failingTests
                  );
                  result = result.filter(
                    (item) => item.testGroupName !== testGroup
                  );
                  result.push(tempObj);
                } else {
                  result.push({
                    overAllResults: "FAIL",
                    failingTests,
                    overallFailingTests: getOverAllResults(failingTests),
                    representableTestGroupName:
                      evalTestNames(representableTestGroupName) || testGroup,
                    testGroupName: testGroup
                  });
                }
              }
            }
            if (showIntermediateValues) {
              const validInnerIntermediateKeys: [string, any][] =
                Object.entries(object).filter(
                  (entry) =>
                    entry[0] !== "propertyAddress" &&
                    entry[0].toLowerCase() !== "sectioneligibility" &&
                    entry[0].toLowerCase() !== "propertyid" &&
                    entry[0].toLowerCase() !== "propertyleveltest" &&
                    (typeof entry[1] !== "string" ||
                      (typeof entry[1] === "string" &&
                        entry[1].toLowerCase() !== "fail" &&
                        entry[1].toLowerCase() !== "waived"))
                );
              if (validInnerIntermediateKeys.length > 0) {
                const otherValues = validInnerIntermediateKeys.map((item) => {
                  const fieldDetails =
                    evaluationTestFieldTypes[testGroup] &&
                    evaluationTestFieldTypes[testGroup][item[0]];
                  const representableTestName =
                    tempResponse && tempResponse[`testNames.${item[0]}`];
                  return {
                    fieldName:
                      eval(representableTestName) ||
                      guidlineTestsMap[item[0]] ||
                      item[0],
                    fieldValue: item[1],
                    fieldType: fieldDetails?.fieldType,
                    isTest: item[1] ? fieldDetails?.isTest : false,
                    propertyId
                  };
                });
                const index = result.findIndex(
                  (item) => item.testGroupName === testGroup
                );
                if (index >= 0) {
                  const tempObj = result[index];
                  tempObj.intermediateValues =
                    tempObj.intermediateValues &&
                    tempObj.intermediateValues.length > 0
                      ? [...tempObj.intermediateValues, ...otherValues]
                      : otherValues;
                  result = result.filter(
                    (item) => item.testGroupName !== testGroup
                  );
                  result.push(tempObj);
                } else if (tests.includes(testGroup)) {
                  result.push({
                    overAllResults: "PASS",
                    failingTests: [],
                    intermediateValues: otherValues,
                    representableTestGroupName:
                      eval(representableTestGroupName) || testGroup,
                    testGroupName: testGroup
                  });
                }
              }
            }
          }
        });
      });
    });
  const order: { [key: string]: number } = {
    loanEconomics: 1,
    logicTest: 2,
    guideLineLimit: 3,
    bridgeRentalPropertyTest: 4,
    maxLeverageTest: 5,
    multiFamilyMixedUse: 6,
    masterTest: 7,
    guidelineTest: 8,
    logicTests: 9,
    maxMinParameters: 10,
    finalCalculation: 11
  };
  result.sort((a, b) =>
    // eslint-disable-next-line no-nested-ternary
    order[a.testGroupName] > order[b.testGroupName]
      ? 1
      : order[b.testGroupName] > order[a.testGroupName]
      ? -1
      : 0
  );
  const OtherSection = waivers?.length ? getGeneralWaivers(waivers) : null;
  if (OtherSection?.failingTests?.length) {
    result.unshift(OtherSection);
  }
  return result;
};

const getGeneralWaivers = (waivers: any) => {
  const OtherSection: any = {
    failingTests: [],
    overAllResults: "",
    overallFailingTests: "",
    representableTestGroupName: "General Waiver Request",
    testGroupName: "General Waiver Request"
  };
  const generalWaivers = waivers?.filter(
    (waiver: any) => waiver?.waiverType === "GeneralWaiver"
  );
  generalWaivers.forEach((generalWaiver: any) => {
    const failingTestObj: any = {
      overAllResults: "",
      propertyId: undefined,
      reasonForFailure: generalWaiver.waiverMitigates[0].description,
      redirectionButtonName: "Loan Details",
      representableTestName: `General Waiver Request`,
      testGroup: "GeneralWaiver",
      testName: "GeneralWaiver",
      waiverData: generalWaiver
    };
    OtherSection.failingTests.push(failingTestObj);
  });
  return OtherSection;
};
export const getOverAllResults = (failingTests: any) => {
  let result = "FAIL";

  const failingTest = failingTests.filter((test: any) => {
    return test.overAllResults === "FAIL";
  });
  failingTest.length > 0 ? (result = "FAIL") : (result = "PASS");
  return result;
};

export const evaluationTestFieldTypes: { [key: string]: any } = {
  loanEconomics: {
    annualizedIOPayments: {
      fieldType: "currency",
      readableName: "Annualized IO Payments"
    },
    dscrCalculation: {
      fieldType: "currency", // changed
      readableName: "DSCR Calculation"
    },
    profitabilityCalculation: {
      fieldType: "percentage",
      readableName: "Profitability Calculation"
    },
    profitabilityTest: {
      isTest: true,
      fieldType: "string",
      readableName: "Profitability Test"
    },
    rentYieldCalculation: {
      fieldType: "currency", // changed
      readableName: "Rent Yield Calculation"
    },
    rentYieldTest: {
      isTest: true,
      fieldType: "string",
      readableName: "Rent Yield Test"
    },
    dscrRequirementTest: {
      isTest: true,
      fieldType: "string",
      readableName: "DSCR Requirement Test"
    },
    monthlyInterest: {
      fieldType: "currency"
    },
    monthlyPrincipalDuringAmortization: {
      fieldType: "currency"
    },
    monthlyQualifyingPI: {
      fieldType: "currency"
    },
    monthlyQualifyingPayment: {
      fieldType: "currency"
    }
    // prePaymentPenaltyMonths not in sheet for dscr
  },
  guideLineLimit: {
    maxTerm13to18Months: {
      fieldType: "string",
      isTest: true,
      readableName: "Maximum Term 13 to 18 Months"
    },
    minPerUnit100kMSA: {
      fieldType: "string",
      isTest: true,
      readableName: "Minimum Per Unit (100k MSA)"
    },
    minPerUnit150kMSA: {
      fieldType: "string",
      isTest: true,
      readableName: "Minimum Per Unit (150k MSA)"
    },
    budget150percentageAsIsPurchasePrice: {
      fieldType: "string",
      isTest: true,
      readableName: "Budget 150% Age As Is Purchase Price"
    },
    interestReserves: {
      fieldType: "string",
      isTest: true,
      readableName: "Interest Reserves"
    },
    maxLeverageArvLtv: {
      fieldType: "string",
      isTest: true,
      readableName: "Maximum Leverage ArvLtv"
    },
    maxLeverageAsIsLtv: {
      fieldType: "string",
      isTest: true,
      readableName: "Maximum Leverage As Is Ltv"
    },
    maxLeverageLtc: {
      fieldType: "string",
      isTest: true,
      readableName: "Maximum Leverage Ltc"
    },
    maxLoanSize: {
      fieldType: "string",
      isTest: true,
      readableName: "Maximum Loan Size"
    },
    maxTerm0To13Months: {
      fieldType: "string",
      isTest: true,
      readableName: "Maximum Term 0 To 13 Months"
    },
    maxTerm18To24Months: {
      fieldType: "string",
      isTest: true,
      readableName: "Maximum Term > 18 Months"
    },
    minLoanSize: {
      fieldType: "string",
      isTest: true,
      readableName: "Minimum Loan Size"
    },
    minMedianFico: {
      fieldType: "string",
      isTest: true,
      readableName: "Minimum Median Fico"
    },
    minPerUnit175kMSA: {
      fieldType: "string",
      isTest: true,
      readableName: "Minimum Per Unit 175kMSA"
    },
    minTerm12Months: {
      fieldType: "string",
      isTest: true,
      readableName: "Minimum Term 12Months"
    },
    msapropertyLocationHighValue150KPart1: {
      fieldType: "string",
      readableName: "MSA Property Location High Value 150K Part1"
    },
    msapropertyLocationHighValue150KPart2: {
      isTest: true,
      fieldType: "string",
      readableName: "MSA Property Location High Value 150K Part2"
    },
    msapropertyLocationHighValue175KPart1: {
      fieldType: "string",
      readableName: "MSA Property Location High Value 175K Part1"
    },
    msapropertyLocationHighValue175KPart2: {
      isTest: true,
      fieldType: "string",
      readableName: "MSA Property Location High Value 175K Part2"
    },
    msapropertyLocationHighValuePart1: {
      fieldType: "string",
      readableName: "MSA Property Location High Value Part1"
    },
    msapropertyLocationHighValuePart2: {
      isTest: true,
      fieldType: "string",
      readableName: "MSA Property Location High Value Part2"
    },
    nonStabilizedPerUnit: {
      isTest: true,
      fieldType: "string",
      readableName: "Non Stabilized Per Unit"
    },
    sfrNotStabilisedMinValue: {
      isTest: true,
      fieldType: "string",
      readableName: "Sfr Not Stabilised Min Value"
    },
    stabilizedPerUnit: {
      isTest: true,
      fieldType: "string",
      readableName: "Stabilized Per Unit"
    }
  },
  logicTest: {
    agedCreditReport: {
      isTest: true,
      fieldType: "string",
      readableName: "Aged Credit Report"
    },
    asIsOrCostGreaterThanArv: {
      isTest: true,
      fieldType: "string",
      readableName: "As Is Or Cost Greater Than Arv"
    },
    purchasegreaterThan120PercentOfAsIs: {
      isTest: true,
      fieldType: "string",
      readableName: "Purchase Greater Than 120% Of As Is"
    },
    recourseTest: {
      isTest: true,
      fieldType: "string",
      readableName: "Recourse Test"
    }
  },
  multiFamilyMixedUse: {
    loanLimitforApproval: {
      fieldType: "string",
      readableName: "Loan Limit for Approval"
    },
    actualDebtYield: {
      fieldType: "percentage",
      readableName: "Actual Debt Yield"
    },
    appraisalNoiorNof: {
      fieldType: "currency", // changed
      readableName: "Appraisal Noi or Nof"
    },
    debtYeildTest: {
      isTest: true,
      fieldType: "string",
      readableName: "Debt Yeild Test"
    },
    minDebtYeildRequirement: {
      fieldType: "string", // number field
      readableName: "Min Debt Yeild Requirement"
    },
    minLiquidityRequirement: {
      fieldType: "string",
      readableName: "Minimum Liquidity Requirement"
    },
    operatingExpenseandVacancy: {
      fieldType: "string", // number field
      readableName: "Operating Expense and Vacancy"
    },
    toorakNoiorNof: {
      fieldType: "currency",
      readableName: "Toorak Noi or Nof"
    },
    underwrittenNoiorNof: {
      fieldType: "currency",
      readableName: "Underwritten Noi or Nof"
    },
    valuePerUnit: {
      fieldType: "currency",
      readableName: "Value Per Unit"
    }
  },
  bridgeRentalPropertyTest: {
    agedPropertyValuation: {
      isTest: true,
      fieldType: "string",
      readableName: "Aged Property Valuation"
    },
    desktopRequirement: {
      isTest: true,
      fieldType: "string",
      readableName: "Desktop Requirement"
    },
    feasibilityReportRequired: {
      fieldType: "string",
      readableName: "Feasibility Report Required"
    },
    homePriceDecline: {
      isTest: true,
      fieldType: "string",
      readableName: "Home Price Decline"
    },
    minimumPropertyValueTest: {
      isTest: true,
      fieldType: "string",
      readableName: "Minimum Property Value Test"
    },
    minSquareFootage: {
      isTest: true,
      fieldType: "string",
      readableName: "Minimum Square Footage"
    },
    propertyLevelTest: {
      isTest: true,
      fieldType: "string",
      readableName: "Property Level Test"
    },
    significantExpansion: {
      fieldType: "string",
      readableName: "Significant Expansion"
    },
    loanStructure: {
      isTest: true,
      fieldType: "string",
      readableName: "Loan Structure"
    }
  },
  maxLeverageTest: {
    maxLTCOne: {
      fieldType: "percentage",
      readableName: "Maximum LTC One"
    },
    maxLTVARV: {
      fieldType: "percentage",
      readableName: "Maximum LTV ARV"
    },
    maxLTVAsIs: {
      fieldType: "percentage",
      readableName: "Maximum LTV As Is"
    }
  }
};

export function getValueFromResults(
  loanEvaluationResult: any,
  loanId: string,
  loanOrPropertyLevel: string,
  testName: any,
  fieldType: string | undefined,
  propertyId?: any
) {
  let returnValue = "";
  if (loanEvaluationResult) {
    if (loanOrPropertyLevel === "propertyResult" && propertyId) {
      const resultObj: { [key: string]: any } =
        loanEvaluationResult?.propertiesResults;
      if (resultObj) {
        const propertyResult: { [key: string]: any } | undefined =
          resultObj[propertyId as string];
        if (propertyResult) {
          const keys = testName.split(".");
          returnValue =
            propertyResult[keys[0] as string] &&
            propertyResult[keys[0] as string][keys[1] as string];
        }
      }
    } else if (loanOrPropertyLevel === "loanResult") {
      const resultObj: { [key: string]: any } = loanEvaluationResult.loanResult;
      if (resultObj) {
        const loanResult: { [key: string]: any } | undefined =
          resultObj[loanId];
        if (loanResult) {
          const keys = testName.split(".");
          returnValue =
            loanResult[keys[0] as string] &&
            loanResult[keys[0] as string][keys[1] as string];
        }
      }
    }
  }
  if (!isEmptyValue(returnValue) && fieldType) {
    returnValue = formatValueByType(returnValue, fieldType);
  }
  return returnValue;
}

export function numberOfWaivers(waiversCreated: any) {
  try {
    if (waiversCreated?.length) {
      const evalWaiver = waiversCreated.filter((ele: any) => {
        return (
          (ele.waiverType === "EvaluationResult" ||
            ele.waiverType === "AdjustmentWaiver" ||
            ele.waiverType === "GeneralWaiver") &&
          ele.waiverInfo?.finalResponse?.toLowerCase() !==
            waiverStatusLowerCase.rescind
        );
      });
      return evalWaiver.length;
    }
  } catch (error) {
    console.log(error);
  }
  return 0;
}
